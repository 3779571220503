import {
  getCustomMinMaxFilterListOptions,
  getCustomMinMaxfilterOptions,
} from "../../Containers/Filterhelper";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import {
  PlannedInvoiceGetList,
  PlannedInvoiceRow,
} from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import {
  CommonCallbackProps,
  StructureItemTable,
} from "src/components/Builders/Table/CommonBuilderTableTypes";
import { Types } from "mongoose";
import { useLocation, useParams } from "react-router-dom";
import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";
import { useState } from "react";
import Constants from "src/constants/Constants";
import useTranslation from "src/hooks/useTranslationWrapper";

interface GetStructureExtraProps {
  contractId?: string;
  currencyCode?: string;
  customers?: Customer[];
  idSummaries?: Types.ObjectId;
  redirectFoundationPath?: string;
}

const usePlannedInvoicesTableStructure = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const { contractId } = useParams<{ contractId?: string }>();
  const [openModal, setOpenModal] = useState(false);
  const [plannedInvoice, setPlannedInvoice] = useState<PlannedInvoiceGetList>();

  const getStructure: UserFormTableGetStructureOptional<
    Partial<PlannedInvoiceGetList>,
    GetStructureExtraProps
  > = (props) => {
    const struture: {
      items: StructureItemTable<Partial<PlannedInvoiceGetList>>[];
    } = {
      items: [
        {
          dataName: "numberFromContract",
          headerLabel: "#",
          type: ({ data, rowIndex }: CommonCallbackProps) => {
            return data[rowIndex]?._id === props?.extraProps?.idSummaries
              ? "text"
              : "link";
          },
          redirectLink: (_props: CommonCallbackProps) =>
            (props?.extraProps?.redirectFoundationPath || location.pathname) +
            "/" +
            String(_props.data[_props.rowIndex]._id),
          getValue: ({ data, rowIndex }: CommonCallbackProps) => {
            const invoice = data[rowIndex];
            return invoice.projectExternalId && !contractId
              ? invoice.projectExternalId + "-" + invoice.number
              : invoice.number;
          },
        },
        {
          dataName: "number",
          headerLabel: "#",
          type: ({ data, rowIndex }: CommonCallbackProps) => {
            return data[rowIndex]?._id === props?.extraProps?.idSummaries
              ? "text"
              : "link";
          },
          sort: true,
          redirectLink: (_props: CommonCallbackProps) =>
            (props?.extraProps?.redirectFoundationPath || location.pathname) +
            "/" +
            String(_props.data[_props.rowIndex]._id),
        },
        {
          type: "link",
          redirectLink: (_props: CommonCallbackProps) =>
            (props?.extraProps?.redirectFoundationPath || location.pathname) +
            "/" +
            String(_props.data[_props.rowIndex]._id),
          dataName: "name",
          headerLabel: "Name",
          sort: true,
          alignColumnContent: "start",
        },
        {
          type: "text",
          dataName: "projectExternalId", // Allow to sort on number instead on name
          headerLabel: "Contract",
          sort: true,
          getValue: ({ data, rowIndex }: CommonCallbackProps) => {
            const invoice = data[rowIndex];
            const name =
              invoice?.projectExternalId + " - " + invoice?.contractName;
            const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
            return name.length > maxLength
              ? name.slice(0, maxLength) + "..."
              : name;
          },
        },
        {
          type: "date",
          dataName: "dueDate",
          headerLabel: "Due date",
          sort: true,
          filter: true,
          filterType: "custom",
          customFilterListOptions: getCustomMinMaxFilterListOptions(
            t("Due date"),
          ),
          filterOptions: getCustomMinMaxfilterOptions(t("Due date"), "date"),
        },
        {
          type: "date",
          dataName: "invoiceDate",
          headerLabel: "Invoice date",
          sort: true,
          filter: true,
          filterType: "custom",
          customFilterListOptions: getCustomMinMaxFilterListOptions(
            t("Invoice date"),
          ),
          filterOptions: getCustomMinMaxfilterOptions(
            t("Invoice date"),
            "date",
          ),
        },
        {
          type: "text",
          dataName: "customerId",
          headerLabel: "Customer name",
          alignColumnContent: "start",
          getValue: ({ data, rowIndex }: CommonCallbackProps) => {
            const id = data[rowIndex]?.customerId || "";
            const customerName = props?.extraProps?.customers?.find(
              (c) => c?._id === id,
            )?.name;
            const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
            return customerName && customerName?.length > maxLength
              ? customerName?.slice(0, maxLength) + "..."
              : customerName;
          },
        },
        {
          type: "text",
          dataName: "ownerNames",
          headerLabel: "Contract owners",
          sort: true,
          getValue: ({ data, rowIndex }: CommonCallbackProps) => {
            const ownerNames = data[rowIndex]?.ownerNames || [];
            return ownerNames.join(", ");
          },
        },
        {
          type: "badge",
          dataName: "status",
          headerLabel: "Status",
          filterOptions: {
            names: [
              "Upcoming",
              "Ready to invoice",
              "Requires input",
              "Late",
              "Exported",
              // "Approved",
            ],
          },
          filter: true,
          sort: true,
          alignColumnContent: "center",
        },
        {
          type: "text_currency_integer",
          currencyCode: props?.extraProps?.currencyCode,
          dataName: "totalExcludingVAT",
          headerLabel: "Tot Excl. VAT",
          sort: true,
          getIsEstimate: ({ data, rowIndex }: CommonCallbackProps) => {
            return data[rowIndex].invoiceRows?.some(
              (row: PlannedInvoiceRow) =>
                row.isQuantityEstimate || row.isPricePerUnitEstimate,
            );
          },
          filter: true,
          filterType: "custom",
          customFilterListOptions: getCustomMinMaxFilterListOptions(
            t("Total excl. VAT"),
          ),
          filterOptions: getCustomMinMaxfilterOptions(
            t("Total excl. VAT"),
            "number",
          ),
          whiteSpace: "nowrap",
          alignColumnContent: "end",
        },
        {
          type: "text_currency_integer",
          currencyCode: props?.extraProps?.currencyCode,
          dataName: "totalVAT",
          headerLabel: "VAT",
          sort: true,
          getIsEstimate: ({ data, rowIndex }: CommonCallbackProps) => {
            return data[rowIndex].invoiceRows?.some(
              (row: PlannedInvoiceRow) =>
                row.isQuantityEstimate || row.isPricePerUnitEstimate,
            );
          },
          whiteSpace: "nowrap",
          alignColumnContent: "end",
        },
        {
          type: "text_currency_integer",
          currencyCode: props?.extraProps?.currencyCode,
          dataName: "total",
          headerLabel: "Total",
          sort: true,
          getIsEstimate: ({ data, rowIndex }: CommonCallbackProps) => {
            return data[rowIndex].invoiceRows?.some(
              (row: PlannedInvoiceRow) =>
                row.isQuantityEstimate || row.isPricePerUnitEstimate,
            );
          },
          filter: true,
          filterType: "custom",
          customFilterListOptions: getCustomMinMaxFilterListOptions(
            t("Total amount"),
          ),
          filterOptions: getCustomMinMaxfilterOptions(
            t("Total amount"),
            "number",
          ),
          whiteSpace: "nowrap",
          alignColumnContent: "end",
        },
        {
          dataName: "export",
          cellLabel: ({ data, rowIndex }: CommonCallbackProps) =>
            data[rowIndex]?.approved ? "Approved" : "Approve",
          type: "button",
          permissionType: "exportRights",
          onClick: ({ data, rowIndex }: CommonCallbackProps) => {
            setPlannedInvoice(data[rowIndex]);
            setOpenModal(true);
          },
          disabled: ({ data, rowIndex }: CommonCallbackProps) =>
            data[rowIndex]?.approved,
          showCellWhen: ({ data, rowIndex }: CommonCallbackProps) =>
            // Sum row has no status
            data[rowIndex].status === undefined
              ? false
              : data[rowIndex].status !== "exported",
        },
      ].map(
        (item, i) =>
          ({
            ...item,
            // Show "Sum" for Column 0 or 1
            ...(i !== 0 &&
            i !== 1 &&
            item.type !== "text_currency_integer" &&
            !item?.showCellWhen
              ? {
                  showCellWhen: (_props) =>
                    _props.data[_props.rowIndex]?._id !==
                    props?.extraProps?.idSummaries,
                }
              : {}),
          }) as StructureItemTable,
      ),
    };
    return struture;
  };

  return { contractId, getStructure, openModal, setOpenModal, plannedInvoice };
};

export default usePlannedInvoicesTableStructure;
