import { ContractInsightWithPermissions } from "src/accurasee-backend-types/app/insight/insight.types";
import { ContractStatus } from "src/accurasee-backend-types/app/contracts/contract.types";
import { getStructure } from "./ContractInsightsTableStructure";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";
import { useGetContractTypesQuery } from "src/redux/services/ContractTypesServices";
import { useGetInsightContractsQuery } from "src/redux/services/InsightService";
import { useState } from "react";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ContractInsightsDateFilter from "./ContractInsightsDateFilter";
import getSummaries from "src/utils/getSummaries";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Page from "src/components/Basic/Mixed/Pages/Page";
import toDay from "src/utils/date";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";

export type Filter = {
  status: ContractStatus[];
  sortBy: string;
  sort: "asc" | "desc";
  // Default is this fiscal year, for ex. from 1.1.2023 - today's date
  startDate: Date;
  endDate: Date;
};

interface ContractInsightsTableProps {
  filter: Filter;
}

const ContractInsightsTable = ({ filter }: ContractInsightsTableProps) => {
  const { data: companyQuery, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);
  const company = companyQuery?.company;

  const { data: contractTypesQuery, isLoading: isLoadingContractType } =
    useGetContractTypesQuery(undefined);
  const contractTypes = contractTypesQuery?.data || [];

  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery(undefined);
  const users = usersResponse?.data || [];

  const [filterData, setFilterData] = useState({
    startDate: filter.startDate,
    endDate: filter.endDate,
  });

  const {
    options,
    data: contracts,
    isLoading,
    isFetching,
  } = useMUIDataTableOptionsServerSide<ContractInsightWithPermissions>({
    dataName: "contract insights",
    defaultPaginationOptions: [10, 15, 50, 100, 200],
    filterInit: {
      base: {
        pageSize: 15,
        sort: filter?.sort,
        sortBy: filter?.sortBy,
      },
      match: {
        endDate: toDay(filterData?.endDate),
        startDate: toDay(filterData?.startDate),
        status: filter.status,
      },
    },
    filterOptions: {
      selectableRows: "none",
    },
    triggerChangeOnFilterInit: ["endDate", "startDate"],
    useGetDataQuery: useGetInsightContractsQuery,
  });

  // To add yearToDate and result
  const contractsWithAdditionalData = contracts?.map((contract, i) => {
    const { costs, incomes, costsThisYear, incomesThisYear } = contract;
    const yearToDate =
      typeof costsThisYear === "number" && typeof incomesThisYear === "number"
        ? incomesThisYear - costsThisYear
        : 0;

    const result =
      typeof costs === "number" && typeof incomes === "number"
        ? incomes - costs
        : 0;
    return { ...contract, result, yearToDate, number: i + 1 };
  });

  const { dataWithSummaries: contractsWithSummaries } = getSummaries({
    data: contractsWithAdditionalData,
    sumTextKey: "number",
    keys: [
      { name: "yearToDate" },
      { name: "result" },
      { name: "incomes" },
      { name: "costs" },
      { name: "plannedInvoices" },
    ],
  });

  const structure = getStructure({
    extraProps: {
      contractTypes,
      currencyCode: company?.currencyCode,
      users,
    },
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: contractsWithSummaries,
      disabled: true,
      item,
    }),
  );

  return (
    <Page
      label="Contract insights"
      sx={{ borderRadius: "8px" }}
      breadcrumbs={[{ label: "Insights" }, { label: "Contract" }]}
      boxRight={
        <ContractInsightsDateFilter
          filterData={filterData}
          setFilterData={setFilterData}
        />
      }
      isLoading={
        isLoading || isLoadingCompany || isLoadingContractType || isLoadingUsers
      }
    >
      <MuiDataTableWrapper
        data={contractsWithSummaries}
        columns={columns}
        isFetching={isFetching}
        options={options}
        tableType="main-with-filter"
        noBackground
      />
    </Page>
  );
};

export default ContractInsightsTable;
