import {
  DisplayData,
  FilterType,
  MUIDataTableColumn,
  MUIDataTableColumnDef,
  MUIDataTableOptions,
  MUIDataTableState,
} from "mui-datatables";
import { cleanObject } from "src/utils/removeKeysWithEmptyStringAsValue";
import { useContext, useEffect } from "react";
import { UseMUIDataTableFilterStatesReturn } from "src/hooks/useMUIDataTableFilterStates";
import { UserStateContext } from "src/context/UserStateProvider";

const usePersistTableFilter = ({
  id,
  onFilterChange: _onFilterChange,
  onChangeAdditionalFilter,
}: {
  id: string;
  onFilterChange?: MUIDataTableOptions["onFilterChange"];
  onChangeAdditionalFilter?: UseMUIDataTableFilterStatesReturn["setAdditionalFilter"];
}) => {
  const {
    getUserStateTable,
    checkIfUserStateTableIsUninitialized,
    checkIfUserStateTableINeedsToBeConvertedToNewStructure,
    setUserStateTable,
  } = useContext(UserStateContext);

  useEffect(() => {
    const userStateByTableId = getUserStateTable(id);

    if (userStateByTableId && onChangeAdditionalFilter) {
      // Using cleanObject helps the empty filters not impact some initial filter settings
      const newFilters = cleanObject(userStateByTableId?.filter);
      onChangeAdditionalFilter({
        ...newFilters,
      });
    }

    // Need to set user state either if it is uninitialized or
    // if it has the old strucutre this structure needs to be
    // converted to new and then set. getUserStateTable(id) always
    // need to return and object since it can be used before this
    // useEffect is performed at end of rendering. At that point
    // this code makes sure that the user state is updated with default
    // values or old converted structure. If state exists and is in new
    // format state thus not need to be updated
    if (
      checkIfUserStateTableIsUninitialized({ id }) ||
      checkIfUserStateTableINeedsToBeConvertedToNewStructure({ id })
    ) {
      setUserStateTable({ id, data: userStateByTableId });
    }
  }, []);

  const onFilterChange = (
    changedColumn: string | MUIDataTableColumn | null,
    filterList: MUIDataTableState["filterList"],
    type: FilterType | "chip" | "reset",
    changedColumnIndex: number,
    _displayData: DisplayData,
  ) => {
    const columnName = changedColumn?.toString() || "";
    const updatedFilter = filterList[changedColumnIndex];

    const userStateByTableId = getUserStateTable(id);

    if (_onFilterChange) {
      _onFilterChange(
        changedColumn,
        filterList,
        type,
        changedColumnIndex,
        _displayData,
      );
    }

    setUserStateTable({
      id,
      data: {
        ...userStateByTableId,
        filter: {
          ...userStateByTableId?.filter,
          [columnName]: updatedFilter,
        },
      },
    });
  };

  const onSetFilterList = (columns: MUIDataTableColumnDef[]) => {
    if (typeof columns === "string") return;

    const userStateByTableId = getUserStateTable(id);

    for (const c of columns) {
      if (typeof c === "string") continue;

      if (userStateByTableId) {
        if (c.options && userStateByTableId?.filter?.hasOwnProperty(c.name)) {
          c.options.filterList = userStateByTableId.filter[c.name];
        }
      }
    }
  };

  return { onFilterChange, onSetFilterList };
};

export default usePersistTableFilter;
