import ButtonCancel from "../../Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "../../Basic/Simple/Buttons/ButtonGroup";
import ButtonUpdate from "../../Basic/Simple/Buttons/ButtonUpdate";
import DateItem from "../../Builders/Container/ItemTypes/DateItem";
import FillerItem from "../../Builders/Container/ItemTypes/FillerItem";
import ModalWrapper from "../../Basic/Mixed/Modals/ModalWrapper";
import Section from "../../Basic/Simple/Sections/Section";
import SectionContainer from "../../Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "../../Basic/Mixed/Sections/SectionWithButtonContainer";
import contractsApi from "src/redux/services/ContractService";
import parseRestApiErrorMessage from "src/utils/parseRestApiErrorMessage";
import toDay from "src/utils/date";
import { ContractExtended } from "../../../utils/getDimensionItems";
import { LinearProgress } from "@mui/material";
import { PlannedInvoice } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { getContractRenewalDate } from "src/screens/App/Contracts/[contractId]/Contract/ContractStructure";
import { isBefore } from "date-fns";
import { useSnackbar, VariantType } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PlannedInvoiceApi, {
  PaginationPlannedInvoices,
} from "src/redux/services/PlannedinvoiceService";

interface CancelContractModalProps {
  openModal: boolean;
  handleCloseModal: () => void;
  contractId: string;
  contract: ContractExtended | undefined;
  setData: (data: any) => void;
}

const CancelContractModal = ({
  openModal,
  handleCloseModal,
  contractId,
  contract,
  setData,
}: CancelContractModalProps) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [endDate, setEndDate] = useState(
    toDay(contract?.endDate || new Date()),
  );
  const [helperText, setHelperText] = useState("");
  const [cancellingContract, setCancellingContract] = useState(false);

  const [patchContract] = contractsApi.usePatchContractMutation();
  const { data: PlannedInvoices, isLoading } =
    PlannedInvoiceApi.useGetPlannedInvoicesQuery({
      contractId,
      exported: true,
    });

  const onChangeDate = (data: { endDate: Date }) => {
    const updateEndDate = data.endDate;

    // To show warning when date is selected
    if (contract) {
      if (contract && isBefore(updateEndDate, new Date(contract.startDate))) {
        setHelperText(t("Can't set cancellation date before start date"));
      } else if (
        PlannedInvoices &&
        (PlannedInvoices as PaginationPlannedInvoices).data.length > 0
      ) {
        const isPlannedInvoice = (
          PlannedInvoices as PaginationPlannedInvoices
        )?.data.some((plannedInvoice: PlannedInvoice) =>
          isBefore(updateEndDate, new Date(plannedInvoice.invoiceDate)),
        );
        if (isPlannedInvoice) {
          setHelperText(
            t(
              "Can't set cancellation date before exported invoice's invoice date",
            ),
          );
        } else {
          setHelperText("");
        }
      } else if (contract.endDate && contract.cancellationTerms) {
        let cutOffDate = getContractRenewalDate(
          contract.endDate,
          contract.cancellationTerms,
        )!;
        if (cutOffDate && isBefore(updateEndDate, cutOffDate)) {
          setHelperText(
            `${t("End date needs to come after")} ${toDay(cutOffDate)} ${t(
              "(determined by cancellation terms)",
            )}`,
          );
        } else {
          setHelperText("");
        }
      } else {
        setHelperText("");
      }
    }
    setEndDate(toDay(updateEndDate));
  };

  const onCloseModal = () => {
    setHelperText("");
    setEndDate(toDay(contract?.endDate || new Date()));
    handleCloseModal();
  };

  const onSubmit = async () => {
    let msg = "Contract has been cancelled";
    let variant: VariantType = "success";
    try {
      const payload: any = {
        id: contractId,
        projectId: contract?.projectId,
        startDate: contract?.startDate,
        endDate,
        status: "cancelled",
      };

      const result: any = await patchContract(payload);
      if (!result) {
        return;
      }
      if (result?.error) {
        throw result?.error;
      }

      setData({ ...contract, endDate });
      handleCloseModal();
    } catch (err: any) {
      msg =
        "Could not cancel contract! Error: " + parseRestApiErrorMessage(err);
      variant = "error";
      if (err.data?.error?.code === 110) {
        const newHelperText = err.data.error.details[0]?.message;
        setHelperText(newHelperText);
      }
    }

    enqueueSnackbar(msg, {
      variant,
    });
    return;
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <ModalWrapper
      openModal={openModal}
      handleCloseModal={onCloseModal}
      label={"Contract cancellation"}
      subLabel={"Select the cancellation date"}
      alignTextCenter
    >
      <SectionContainer>
        <Section md={12} lg={12} boxStyle={{ paddingTop: 0 }}>
          <FillerItem
            translationNamespace={"common"}
            key={"filler"}
            item={{ type: "filler", gridProps: { xs: 3 } }}
            itemDisabled={false}
          />
          <DateItem
            translationNamespace={"common"}
            key={"date"}
            item={{
              type: "date",
              gridProps: { xs: 6 },
              dataName: "endDate",
              setFormData: onChangeDate,
            }}
            itemDisabled={false}
            helperText={{ endDate: helperText }}
            data={{ endDate: new Date(endDate) }}
          />
        </Section>
      </SectionContainer>
      <SectionWithButtonContainer buttonCenter>
        <ButtonGroup>
          <ButtonCancel onClick={handleCloseModal} />
          <ButtonUpdate
            isValid={helperText === ""}
            isSubmitting={cancellingContract}
            onSubmit={async () => {
              setCancellingContract(true);
              await onSubmit();
              setCancellingContract(false);
              handleCloseModal();
            }}
            submitData={{ endDate, ...contract?.permissions }}
            initialSubmitData={{}}
            permissions={contract?.permissions}
          />
        </ButtonGroup>
      </SectionWithButtonContainer>
    </ModalWrapper>
  );
};
export default CancelContractModal;
