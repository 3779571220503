/**
 *Created by Mikael Lindahl on 2022-12-20
 */

import { Box, Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import useStyles from "../BuildItemsStyles";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import throwErrorDataName from "../Utils/throwErrorDataName";
import getOptions from "../Utils/getOptions";
import getRequired from "../Utils/getRequired";
import setValue from "../Utils/setValue";
import { CommonBuilderContainerTypes } from "../CommonBuilderContainerTypes";
import useTranslation from "../../../../hooks/useTranslationWrapper";
import getHelperText from "../Utils/getHelperText";
import getSelectedOptions from "../Utils/getSelectedOptions";
import getIsInActiveWarning from "../../../../utils/getIsInActiveWarning";
import getShowWhen from "../Utils/getShowWhen";
import getValue from "../Utils/getValue";

type AutocompleteItemTProps = CommonBuilderContainerTypes & {
  onAdd?: () => void;
};

const AutocompleteItem = (props: AutocompleteItemTProps) => {
  const classes = useStyles();
  const [t] = useTranslation(props.translationNamespace);

  let commonCallbackProps = {
    data: props.data,
    item: props.item,
  };

  const options = getOptions(commonCallbackProps);

  let getHelperTextProps = {
    helperText: props.helperText,
    item: props.item,
    t,
  };

  const selectedOptions = getSelectedOptions(commonCallbackProps);

  const required = getRequired(commonCallbackProps);

  if (!props.item.dataName) {
    return throwErrorDataName({
      item: props.item,
      itemName: "AutocompleteItem",
    });
  }

  const value = !selectedOptions
    ? null
    : props.item?.selectionOptions?.multiple
      ? selectedOptions
      : selectedOptions[0] || null;

  // Rare case: its valid value is not in options
  const selectedValue = getValue(props);

  return getShowWhen(commonCallbackProps) ? (
    <Grid item xs={12} {...props.item.gridProps}>
      <Autocomplete
        loading={props.item.selectionOptions?.loading}
        // Note: Do not use disablePortal since it removes the border frame
        // from Autocomplete when displayed in modal
        size="small"
        disableClearable={props.item?.selectionOptions?.disableClearable}
        disabled={props.itemDisabled}
        id={props.item.dataName}
        // Added this since sometimes get error even though options exists in list
        isOptionEqualToValue={(option, value) => option.value === value.value}
        options={options || []}
        title={!selectedOptions ? "" : selectedOptions[0]?.label || ""}
        limitTags={2} // limit the number of displayed options
        // Note: Forr css, if item is multiple, its width should be full
        // If its width is full, for example xs:12,
        // the layout looks better than xs: 6 and there’s another component sitting next to it
        multiple={props.item?.selectionOptions?.multiple}
        // if item.selectionOptions.multiple then value should be array otherwise string for single selector
        value={value} // To make it controlled on undefined set it to null
        getOptionLabel={(value) => {
          let label = typeof value === "object" ? value.label : value;
          return label === null ? "" : label;
        }}
        // Needed in order to allow for select options with same label
        renderOption={(props, item) => {
          return (
            <Box
              component="i"
              {...props}
              key={props.id}
              sx={{ color: !item.isActive ? "var(--gray-3)" : "inherit" }}
            >
              {item.label}
            </Box>
          );
        }}
        onChange={(_, value) => {
          const newValue = Array.isArray(value)
            ? value?.map((v) => v?.value)
            : value?.value;

          let changeProps = { ...commonCallbackProps, value: newValue };

          if (props.item.setFormDataCustom) {
            props.item.setFormDataCustom(changeProps);
          } else {
            setValue(changeProps);
          }
        }}
        onInputChange={props.item.selectionOptions?.onInputChange}
        renderInput={(params) => {
          return (
            <Grid container key={props.item.dataName}>
              <Grid item xs={12}>
                <InputLabel
                  required={required}
                  shrink
                  htmlFor={props.item.dataName}
                  className={classes.labelFieldStyle}
                >
                  {t(props.item.label)}
                </InputLabel>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box flexGrow={1}>
                    <TextField
                      {...params}
                      sx={{
                        width: "100%",
                        "& .MuiAutocomplete-inputRoot": {
                          padding:
                            "var(--space-12px) var(--space-16px) !important",
                        },
                        "& .MuiAutocomplete-input": {
                          padding: "0  !important",
                        },
                        "& .MuiAutocomplete-tag": {
                          margin: "0 var(--space-2px)",
                          height: "fit-content",
                        },
                        ...((selectedOptions?.some(
                          (o) => o.isActive === false,
                        ) ||
                          (selectedValue && value === null)) && {
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "var(--warning)", // Set your desired color here
                            },
                            "&:hover fieldset": {
                              borderColor: "var(--warning)", // Color on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "var(--warning)", // Color when focused
                            },
                          },
                        }),
                      }}
                      style={{ width: "100%" }}
                      helperText={
                        selectedOptions?.some((o) => o.isActive === false) ||
                        selectedValue
                          ? getIsInActiveWarning([
                              ...(selectedOptions ? selectedOptions : []),
                              ...(selectedValue && value === null
                                ? [
                                    {
                                      label: props.item.label || "item",
                                      value: "",
                                      isActive: false,
                                    },
                                  ]
                                : []),
                            ])
                          : getHelperText(getHelperTextProps)
                      }
                      error={Boolean(getHelperText(getHelperTextProps))}
                      placeholder={
                        props.item?.selectionOptions?.inputPlaceholder
                      }
                    />
                  </Box>
                  {props.item.onClick && (
                    <Box>
                      <IconButton
                        aria-label="add-customer"
                        color="primary"
                        onClick={() =>
                          props.item.onClick &&
                          props.item?.onClick(commonCallbackProps)
                        }
                        disabled={props.itemDisabled}
                      >
                        <AddCircleIcon fontSize="large" />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          );
        }}
      />
    </Grid>
  ) : (
    <></>
  );
};

export default AutocompleteItem;
