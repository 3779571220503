import PizZipUtils from "pizzip/utils";
import { DropzoneArea } from "material-ui-dropzone";
import { MicrosoftDriveService } from "../../../../context/MicrosoftDriveProvider/Lib/MicrosoftDriveService";
import { getProjectPath } from "../../../../context/MicrosoftDriveProvider/Utils/PathHelper";
import useTranslation from "../../../../hooks/useTranslationWrapper";
import { useState } from "react";
import { Box } from "@mui/material";
import useStyles from "./DropzoneMicrosoftDriveFIleUploadStyles";

type FileUploaderProps = {
  collectionName: "contract_offers" | "contracts";
  leafFolder?: string;
  invalidate: () => void;
  categories: { group: string; type: string };
  microsoftDriveClient: MicrosoftDriveService | null;
};

const DropzoneMicrosoftDriveFileUpload = ({
  collectionName,
  categories,
  leafFolder,
  invalidate,
  microsoftDriveClient,
}: FileUploaderProps) => {
  const [t] = useTranslation();
  const classes = useStyles();

  // State to keep track of files that have been uploaded
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const handleFileChange = (files: File[]) => {
    if (!microsoftDriveClient || !leafFolder) {
      return;
    }

    const projectPath = getProjectPath({
      collectionName,
      config: microsoftDriveClient.config,
      leafFolder,
    });

    // Identify new files that haven't been uploaded yet
    const newFiles = files.filter(
      (file) =>
        !uploadedFiles.some((uploadedFile) => isSameFile(uploadedFile, file)),
    );

    // Update the state with all current files to track uploaded ones
    setUploadedFiles(files);

    newFiles.forEach((selectedFile) => {
      const url = URL.createObjectURL(selectedFile);
      const p = new Promise((resolve, reject) => {
        PizZipUtils.getBinaryContent(
          url,
          (error: Error | null, content: string) => {
            if (error) {
              reject(error);
              return;
            }
            microsoftDriveClient
              .uploadFile(selectedFile.name, content, projectPath)
              .then((item) => {
                const metadata = {
                  description: encodeURIComponent(JSON.stringify(categories)),
                };
                microsoftDriveClient
                  .addMetadata({ id: item.id, metadata })
                  .then((data) => {
                    resolve(data);
                    invalidate();
                  })
                  .catch((e) => {
                    reject(e);
                  });
              })
              .catch((e) => {
                reject(e);
              });
          },
        );
      });
      p.catch((e) => {
        console.error(e);
      });
    });
  };

  // Helper function to compare files based on name, size, and last modified date
  const isSameFile = (file1: File, file2: File) => {
    return (
      file1.name === file2.name &&
      file1.size === file2.size &&
      file1.lastModified === file2.lastModified
    );
  };

  return (
    <Box sx={{ p: 2 }} className={classes.dropzoneBox}>
      <DropzoneArea
        onChange={handleFileChange}
        showPreviewsInDropzone={false}
        showPreviews={false}
        maxFileSize={Infinity}
        filesLimit={100}
        dropzoneText={t("Drag and drop files here or click")}
      />
    </Box>
  );
};

export default DropzoneMicrosoftDriveFileUpload;
