/**
 *Created by Mikael Lindahl on 2023-11-28
 */

import { ContractInsightWithPermissions } from "src/accurasee-backend-types/app/insight/insight.types";
import { ContractTypeWithPermissions } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { Types } from "mongoose";
import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";
import Constants from "src/constants/Constants";
import getCustomerName from "src/utils/getCustomerName";
import {
  CommonCallbackProps,
  StructureItemTable,
} from "src/components/Builders/Table/CommonBuilderTableTypes";

interface GetStructureExtraProps {
  contractTypes?: ContractTypeWithPermissions[];
  currencyCode?: string;
  users?: ReturnUser[];
}

export const getStructure: UserFormTableGetStructureOptional<
  Partial<ContractInsightWithPermissions>,
  GetStructureExtraProps
> = (props) => {
  return {
    items: [
      {
        dataName: "number",
        headerLabel: "#",
        type: "text",
        sort: true,
      },
      {
        type: "text",
        dataName: "projectName",
        headerLabel: "Project",
        sort: true,
        getValue: ({ data, rowIndex }: CommonCallbackProps) => {
          const name = data[rowIndex]?.projectName || "";
          const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
          return name.length > maxLength
            ? name.slice(0, maxLength) + "..."
            : name;
        },
      },
      {
        dataName: "name",
        headerLabel: "Description",
        type: "link",
        sort: true,
        redirectLink: (props: CommonCallbackProps) =>
          "/app/contracts/" + String(props.data[props.rowIndex].contractId),
        getValue: ({ data, rowIndex }: CommonCallbackProps) => {
          const name = data[rowIndex]?.name || "";
          const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
          return name.length > maxLength
            ? name.slice(0, maxLength) + "..."
            : name;
        },
        sx: { minWidth: "378px" },
      },
      {
        type: "badge",
        dataName: "status",
        headerLabel: "Status",
        sort: true,
        filter: true,
        filterOptions: {
          names: [
            "Upcoming",
            "Ongoing",
            "Finished",
            "Locked",
            "Archived",
            "Invoiced",
            "Partially invoiced",
            "Cancelled",
          ],
        },
        alignColumnContent: "center",
      },
      {
        type: "text",
        dataName: "customerName",
        headerLabel: "Customer",
        sort: true,
        getValue: ({ data, rowIndex }: CommonCallbackProps) => {
          return getCustomerName(data[rowIndex]?.customerId, {
            maxChars: Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE,
            excludeExternalId: true,
          });
        },
      },
      {
        type: "text",
        dataName: "ownerIds",
        headerLabel: "Contract owners",
        sort: true,
        getValue: ({ data, rowIndex }: CommonCallbackProps) => {
          const owners =
            data[rowIndex]?.ownerIds?.map((ownerId: Types.ObjectId) => {
              const owner = props?.extraProps?.users?.find(
                (e) => e._id === ownerId,
              );
              return `${owner?.firstName} ${owner?.lastName}`;
            }) || [];

          return owners?.join(", ") || " ";
        },
        filter: true,
        filterOptions: {
          names: props?.extraProps?.users?.map((user) => String(user._id)),
          logic(value: any, filters: string[]) {
            // Convert filter names back to IDs for filtering logic
            const filterIds = filters.map((filterId: string) =>
              String(
                props?.extraProps?.users?.find(
                  (user) => String(user._id) === filterId,
                )?._id,
              ),
            );
            return !filterIds.includes(value);
          },
          renderValue: (value: string) => {
            const user = props?.extraProps?.users?.find(
              (u) => String(u._id) === value,
            );
            return `${user?.firstName} ${user?.lastName}`;
          },
          fullWidth: true,
        },
        customFilterListOptions: {
          render: (value: string) => {
            const user = props?.extraProps?.users?.find(
              (u) => String(u._id) === value,
            );
            return `${user?.firstName} ${user?.lastName}`;
          },
        },
      },
      {
        type: "text",
        dataName: "contractTypeId",
        headerLabel: "Contract type",
        sort: true,
        getValue: ({ data, rowIndex }: CommonCallbackProps) => {
          const result = props?.extraProps?.contractTypes?.find(
            (e) => e._id === data[rowIndex]?.contractTypeId,
          );
          return result?.name || "";
        },
      },
      {
        type: "text_currency",
        currencyCode: props?.extraProps?.currencyCode,
        dataName: "yearToDate",
        headerLabel: "Result year to date",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        currencyCode: props?.extraProps?.currencyCode,
        dataName: "result",
        headerLabel: "Results",
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        currencyCode: props?.extraProps?.currencyCode,
        dataName: "incomes",
        headerLabel: "Incomes",
        sort: true,
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        currencyCode: props?.extraProps?.currencyCode,
        dataName: "costs",
        headerLabel: "Costs",
        getValue: ({ data, rowIndex }: CommonCallbackProps) => {
          const { costs } = data[rowIndex];
          return costs && costs > 0 ? `-${costs}` : 0;
        },
        sort: true,
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        currencyCode: props?.extraProps?.currencyCode,
        dataName: "plannedInvoices",
        headerLabel: "Planned invoices",
        sort: true,
      },
      {
        type: "date",
        dataName: "startDate",
        sort: true,
        headerLabel: "Start date",
      },
      {
        type: "date",
        dataName: "endDate",
        sort: true,
        headerLabel: "End date",
      },
    ].map(
      (item, i) =>
        ({
          ...item,
          ...(i !== 0 && item.type !== "text_currency"
            ? { showCellWhen: (_props) => !_props.data[_props.rowIndex]?._id }
            : {}),
        }) as StructureItemTable,
    ),
  };
};
