/**
 *Created by Mikael Lindahl on 2023-11-29
 */

import { useState } from "react";
import isEqual from "lodash/isEqual";

export type FormGlue<T = any, TSubmit = any> = {
  formData: T;
  formDataInitial: T;
  hasTriedToSubmit: boolean;
  isFormValid: boolean;
  isEmpty: boolean;
  isHasDataChanged: boolean;
  setFormData: (data: T) => void;
  setFormDataInitial: (data: T) => void;
  setHasTriedToSubmit: (value: boolean) => void;
  setIsFormValid: (value: boolean) => void;
  setSubmitData: (data: TSubmit) => void;
  setSubmitDataInitial: (data: TSubmit) => void;
  submitData: TSubmit | undefined;
  submitDataInitial: TSubmit | undefined;
};

const useFormGlue = <T = any, TSubmit = any>() => {
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [submitData, setSubmitData] = useState<TSubmit>();
  const [submitDataInitial, setSubmitDataInitial] = useState<TSubmit>();

  const getIsHasDataChanged = (data: any, dataInitial: any) => {
    if (data === null || data === undefined) return false;
    if (Array.isArray(data)) {
      if (data.length === 0) return false;

      if (Array.isArray(dataInitial)) {
        if (data.length !== dataInitial.length) return true;

        return data.every((d, i) => !isEqual(d, dataInitial[i]));
      }
    }
    if (typeof data === "object")
      return Object.keys(data).length !== 0 || !isEqual(data, dataInitial);
  };

  const getIsEmpty = (data: any) => {
    if (data === null || data === undefined) return true;
    if (Array.isArray(data)) return data.length === 0;
    if (typeof data === "object") return Object.keys(data).length === 0;
    return false;
  };

  const isEmpty = getIsEmpty(submitData);

  const isHasDataChanged = getIsHasDataChanged(submitData, submitDataInitial);

  return {
    hasTriedToSubmit,
    isEmpty,
    isFormValid,
    isHasDataChanged,
    setHasTriedToSubmit,
    setIsFormValid,
    setSubmitData,
    setSubmitDataInitial,
    submitData,
    submitDataInitial,
  } as FormGlue<T, TSubmit>;
};

export default useFormGlue;
