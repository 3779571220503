import {
  ReportGetRequestQuery,
  ReportGetResponseColumn,
  ReportGetResponseData,
} from "src/accurasee-backend-types/app/report/report.types";
import { setFormDataDatePair } from "src/utils/date";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import {
  CommonCallbackProps,
  StructureTable,
} from "src/components/Builders/Table/CommonBuilderTableTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import getSelectOptions from "src/utils/getSelectOptions";

export const getStructureFilter: UseFormContainerGetStructure<
  Partial<ReportGetRequestQuery>
> = ({ setFormData, t }) => {
  const structure: StructureContainer<Partial<ReportGetRequestQuery>> = {
    items: [
      {
        type: "date",
        dataName: "startDate",
        label: "From",
        setFormDataCustom: ({ data, value }) => {
          setFormDataDatePair({
            data,
            otherKey: "endDate",
            otherAction: "to_end_of_month",
            value,
            valueKey: "startDate",
            setFormData,
          });
        },
        sx: {
          "& .MuiInputBase-root": { borderRadius: "8px" },
        },
      },
      {
        type: "date",
        dataName: "endDate",
        label: "To",
        setFormDataCustom: ({ data, value }) => {
          setFormDataDatePair({
            data,
            otherKey: "startDate",
            otherAction: "to_start_of_month",
            value,
            valueKey: "endDate",
            setFormData,
          });
        },
        sx: {
          "& .MuiInputBase-root": { borderRadius: "8px" },
        },
      },
      {
        type: "selector",
        dataName: "resolution",
        label: "Resolution",
        options: getSelectOptions({
          data: [
            {
              label: "Week",
              value: "week",
            },
            {
              label: "Month",
              value: "month",
            },
            {
              label: "Quarter",
              value: "quarter",
            },
            {
              label: "Year",
              value: "year",
            },
          ].map((c) => ({ label: t(c.label), value: c.value })),
          isSort: false,
        }),
        setFormData,
        sx: { borderRadius: "8px" },
      },
    ],
  };

  return structure;
};

type StructureServerSideExtraProps<DataType = any> = {
  columns: ReportGetResponseColumn<DataType>[];
};

export const getStructureServerSide: UserFormTableGetStructure<
  Partial<ReportGetResponseData>,
  StructureServerSideExtraProps<Partial<ReportGetResponseData>>
> = ({ extraProps }) => {
  const structure: StructureTable<Partial<ReportGetResponseData>> = {
    items: [],
  };

  if (extraProps?.columns) {
    for (const column of extraProps?.columns) {
      const { dataName, headerLabel, type } = column;

      structure.items.push({
        dataName,
        headerLabel,
        type,
        alignColumnContent: type === "text_currency_integer" ? "end" : "start",
        showCellWhen: ({ data, rowIndex }) =>
          data[rowIndex].filterType !== "empty",
        sx: (props: CommonCallbackProps<Partial<ReportGetResponseData>>) => {
          const { isBold, isLarge } = props.data[props.rowIndex];
          return {
            ...(isBold ? { fontWeight: "bold" } : {}),
            ...(isLarge ? { fontSize: "20px" } : {}),
          };
        },
      });
    }
  }

  return structure;
};

// export const getColumns = ({
//   columns,
//   data,
//   t,
// }: {
//   columns: ReportGetResponseColumn[];
//   data: ReportGetResponseData[];
//   t: TFunction;
// }) => {
//   return (
//     columns.map((c) => ({
//       ...c,
//       headerLabel: c.dataName,
//       name: c.dataName,
//       label: c.label,
//       options: {
//         display: true,
//         filter: false,
//         sort: false,
//         setCellHeaderProps: () => ({
//           style: {
//             textAlign: c.type === "text_currency_integer" ? "end" : "start",
//           },
//         }),
//         ...(c.type === "text_currency_integer"
//           ? getColumnOptions({
//               ColumnItem: TextColumnItem,
//               data,
//               isCurrency: true,
//               justifyContent: "end",
//               item: {
//                 dataName: c.dataName,
//                 headerLabel: c.label,
//                 type: "number",
//                 showCellWhen: ({ data, rowIndex }) =>
//                   data[rowIndex].filterType !== "empty",
//               },
//               t,
//             })
//           : {}),
//       },
//     })) || []
//   );
// };
