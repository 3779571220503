import {
  FormGroup,
  FormLabel,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { MUIDataTableColumn, MUIDataTableState } from "mui-datatables";
import FilterCreateContainer, { minMaxValue } from "./FilterCreateContainer";

function getCustomMinMaxFilterListOptions(dataLabel: string) {
  return {
    render: (value: number[]) => {
      if (value[0] && value[1]) {
        return [
          `Min ${dataLabel}: ${value[0]}`,
          `Max ${dataLabel}: ${value[1]}`,
        ];
      } else if (value[0]) {
        return `Min ${dataLabel}: ${value[0]}`;
      } else if (value[1]) {
        return `Max ${dataLabel}: ${value[1]}`;
      }
      return [];
    },
    update: (filterList: string[][], filterPos: number, index: number) => {
      if (filterPos === 0) {
        filterList[index].splice(filterPos, 1, "");
      } else if (filterPos === 1) {
        filterList[index].splice(filterPos, 1);
      } else if (filterPos === -1) {
        filterList[index] = [];
      }
      return filterList;
    },
  };
}

function getCustomMinMaxfilterOptions(
  dataLabel: string,
  type: "number" | "date",
) {
  return {
    logic(value: string, filters: any[]) {
      if (filters[0] && filters[1]) {
        return value < filters[0] || value > filters[1];
      } else if (filters[0]) {
        return value < filters[0];
      } else if (filters[1]) {
        return value > filters[1];
      }
      return false;
    },
    display: (
      filterList: MUIDataTableState["filterList"],
      onChange: (
        val: string | string[],
        index: number,
        column: MUIDataTableColumn,
      ) => void,
      index: number,
      column: MUIDataTableColumn,
    ) => {
      let initialData: minMaxValue;
      if (type === "number") {
        const minValue = Number(filterList[index][0]);
        const maxValue = Number(filterList[index][1]);
        initialData = {
          minNumber: !isNaN(minValue) ? minValue : undefined,
          maxNumber: !isNaN(maxValue) ? maxValue : undefined,
        };
      } else {
        initialData = {
          minDate: filterList[index][0] || "",
          maxDate: filterList[index][1] || "",
        };
      }
      return (
        <>
          <FormLabel
            sx={{
              fontSize: "12px",
              fontWeight: 600,
              paddingBottom: "var(--space-4px)",
            }}
          >
            {dataLabel}
          </FormLabel>
          <FormGroup row={true}>
            <FilterCreateContainer
              column={column}
              filterList={filterList}
              filterName={dataLabel}
              index={index}
              initialData={initialData}
              onChange={onChange}
              type={type}
            />
          </FormGroup>
        </>
      );
    },
  };
}

const getCustomMultiselectFilterOptions = ({
  dataLabel,
  options,
  renderValue,
}: {
  dataLabel: string;
  options: any[];
  renderValue: (v: string) => string;
}) => {
  return {
    logic(value: string, filters: any[]) {
      // Convert filter names back to IDs for filtering logic
      const filterIds = filters.map((filterId) =>
        String(options?.find((option) => String(option._id) === filterId)?._id),
      );
      return !filterIds.includes(value);
    },
    fullWidth: true,
    display: (
      filterList: MUIDataTableState["filterList"],
      onChange: (
        val: string | string[],
        index: number,
        column: MUIDataTableColumn,
      ) => void,
      index: number,
      column: MUIDataTableColumn,
    ) => {
      return (
        <>
          <FormLabel
            sx={{
              fontSize: "12px",
              fontWeight: 600,
              paddingBottom: "var(--space-4px)",
            }}
          >
            {dataLabel}
          </FormLabel>
          <FormControl key={index} variant={"standard"} fullWidth>
            <Select
              multiple
              fullWidth
              value={filterList[index] || []}
              renderValue={(selected) => selected.map(renderValue).join(", ")}
              name={dataLabel}
              onChange={(event) => onChange(event.target.value, index, column)}
              MenuProps={{
                sx: {
                  maxHeight: "232px",
                  "& .MuiPopover-paper": {
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  },
                },
              }}
            >
              {options
                ?.map((option) => String(option?._id))
                ?.map((filterValue, filterIndex) => (
                  <MenuItem value={filterValue} key={filterIndex + 1}>
                    {renderValue(filterValue)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </>
      );
    },
  };
};

export {
  getCustomMultiselectFilterOptions,
  getCustomMinMaxFilterListOptions,
  getCustomMinMaxfilterOptions,
};
