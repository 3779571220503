/**
 *Created by Mikael Lindahl on 2023-03-08
 */

import { InvoicePlanWithPermissions } from "../../../../../accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { StructureItemTable } from "../../../../../components/Builders/Table/CommonBuilderTableTypes";
import { LocationDescriptorObject } from "history";

export type InvoicePlanWithPermissionsExtended = InvoicePlanWithPermissions & {
  rowNumber: string;
};

export const getStructure: (
  onDelete: (rowIndex: number) => void,
  location: LocationDescriptorObject,
) => {
  items: StructureItemTable<InvoicePlanWithPermissionsExtended>[];
} = (onDelete, location) => ({
  items: [
    {
      dataName: "rowNumber",
      headerLabel: "#",
      type: "text",
    },
    {
      dataName: "name",
      headerLabel: "name",
      type: "link",
      redirectLink: (props) =>
        location.pathname + "/" + String(props.data[props.rowIndex]._id),
    },
    {
      type: "badge",
      dataName: "status",
      alignColumnContent: "center",
      headerLabel: "status",
    },
    {
      type: "badge",
      dataName: "customerStatus",
      alignColumnContent: "center",
      headerLabel: "Customer status",
    },
    {
      type: "icon_button",
      iconType: "delete",
      dataName: "delete",
      onClick: ({ rowIndex }) => onDelete(rowIndex),
    },
  ],
});
