import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import Constants from "src/constants/Constants";
import getSummaries from "src/utils/getSummaries";
import InvoiceRowsSubtable from "../InvoiceRowsSubtable";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionForTableWithToolbar from "src/components/Basic/Simple/Sections/SectionForTableWithToolbar";
import useTranslation from "src/hooks/useTranslationWrapper";
import { CustomerInvoiceWithGetList } from "src/accurasee-backend-types/app/customerinvoice/customerinvoice.types";
import { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import {
  CommonCallbackProps,
  StructureItemTable,
} from "src/components/Builders/Table/CommonBuilderTableTypes";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useGetCustomerInvoicesQuery } from "src/redux/services/CustomerInvoiceService";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";

import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";
import {
  getCustomMinMaxFilterListOptions,
  getCustomMinMaxfilterOptions,
} from "../../Containers/Filterhelper";

interface CustomerInvoiceTableProps {
  customerInvoiceFilter: any;
  label?: string;
  includeProjectNumber?: boolean;
  isLoading?: boolean;
  useColumns: string[];
  redirectFoundationPath?: string;
}

const CustomerInvoiceTable = ({
  customerInvoiceFilter,
  label,
  includeProjectNumber,
  isLoading,
  useColumns,
  redirectFoundationPath,
}: CustomerInvoiceTableProps) => {
  const [t] = useTranslation();
  const location = useLocation();
  const { contractId } = useParams<{ contractId: string }>();

  const { data: companyQuery, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);
  const company = companyQuery?.company;

  const {
    options,
    data: customerInvoices,
    isLoading: isCustomerInvoicesLoading,
  } = useMUIDataTableOptionsServerSide<CustomerInvoiceWithGetList>({
    dataName: "customer invoices",
    filterInit: {
      base: {
        ...customerInvoiceFilter,
      },
      match: {
        contractId,
      },
    },
    filterOptions: {
      selectableRows: "none",
      searchPlaceholder: `${t("Search for")} ${t("Number").toLowerCase()}, ${t(
        "Name",
      ).toLowerCase()}, ${t("Customer name").toLowerCase()} ${t("or")} ${t(
        "Status",
      ).toLowerCase()}...`,
    },
    isRowExpandable: ((dataIndex: number) => {
      return customerInvoices.length !== dataIndex;
    }) as MUIDataTableOptions["isRowExpandable"],
    subTableFunction: (rowData: any, rowMeta: any) => {
      const customerInvoice = customerInvoices[rowMeta.dataIndex];
      return (
        <SubTableContainer>
          <InvoiceRowsSubtable
            currencyCode={customerInvoice.currencyCode}
            invoiceRows={customerInvoice.invoiceRows}
            useColumns={[
              "itemDescription",
              "quantity",
              "pricePerUnit",
              "discount",
              "totalExcludingVAT",
              "percentVAT",
              "total",
            ]}
          />
        </SubTableContainer>
      );
    },
    triggerChangeOnFilterInit: contractId ? ["contractId"] : [],
    useGetDataQuery: useGetCustomerInvoicesQuery,
  });
  const { idSummaries, dataWithSummaries: customerInvoicesWithSummaries } =
    getSummaries({
      data: customerInvoices,
      sumTextKey: "invoiceNumber",
      keys: [
        { name: "totalExcludingVAT" },
        { name: "totalVAT" },
        { name: "total" },
      ],
    });
  const customerInvoiceTableStructure: {
    items: StructureItemTable<Partial<CustomerInvoiceWithGetList>>[];
  } = {
    items: [
      {
        dataName: "invoiceNumber",
        headerLabel: "#",
        type: ({ data, rowIndex }: CommonCallbackProps) => {
          return data[rowIndex]?._id === idSummaries ? "text" : "link";
        },
        redirectLink: (props: CommonCallbackProps) =>
          (redirectFoundationPath || location.pathname) +
          "/" +
          String(props.data[props.rowIndex]._id),
        sort: true,
      },
      {
        type: "text",
        dataName: "projectExternalId", // Allow to sort on number instead on name
        headerLabel: "Contract",
        sort: true,
        getValue: ({ data, rowIndex }: CommonCallbackProps) => {
          const invoice = data[rowIndex];
          if (
            invoice?.projectExternalId &&
            invoice?.projectName &&
            includeProjectNumber
          ) {
            const name =
              invoice?.projectExternalId + " - " + invoice?.projectName;
            const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
            return name.length > maxLength
              ? name.slice(0, maxLength) + "..."
              : name;
          } else return t("Not assigned to contract");
        },
      },
      {
        type: "link",
        redirectLink: (props: CommonCallbackProps) =>
          (redirectFoundationPath || location.pathname) +
          "/" +
          String(props.data[props.rowIndex]._id),
        dataName: "name",
        headerLabel: "Name",
        sort: true,
      },
      {
        type: "date",
        dataName: "dueDate",
        sort: true,
        headerLabel: "Due date",
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Due date"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(t("Due date"), "date"),
      },
      {
        type: "date",
        dataName: "invoiceDate",
        sort: true,
        headerLabel: "Invoice date",
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Invoice date"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(t("Invoice date"), "date"),
      },
      {
        type: "text",
        dataName: "customerName",
        headerLabel: "Customer name",
        sort: true,
        getValue: ({ data, rowIndex }: CommonCallbackProps) => {
          const name = data[rowIndex]?.customerName || "";
          const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
          return name.length > maxLength
            ? name.slice(0, maxLength) + "..."
            : name;
        },
      },
      {
        type: "badge",
        dataName: "status",
        headerLabel: "Status",
        sort: true,
        filter: true,
        filterOptions: {
          names: [
            "Exported",
            "Cancelled",
            "Invoiced",
            "Invoiced overdue",
            "Fully paid",
          ],
        },
        alignColumnContent: "center",
      },
      {
        type: "text_currency_integer",
        currencyCode: company?.currencyCode,
        dataName: "totalExcludingVAT",
        headerLabel: "Tot Excl VAT",
        sort: true,
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Total excl. VAT"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(
          t("Total excl. VAT"),
          "number",
        ),
        alignColumnContent: "end",
      },
      {
        type: "text_currency_integer",
        currencyCode: company?.currencyCode,
        dataName: "totalVAT",
        headerLabel: "VAT",
        sort: true,
        alignColumnContent: "end",
      },
      {
        type: "text_currency_integer",
        currencyCode: company?.currencyCode,
        dataName: "total",
        headerLabel: "Total",
        sort: true,
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Total amount"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(
          t("Total amount"),
          "number",
        ),
        alignColumnContent: "end",
      },
    ].map(
      (item, i) =>
        ({
          ...item,
          ...(i !== 0 && item.type !== "text_currency_integer"
            ? {
                showCellWhen: (_props) =>
                  _props.data[_props.rowIndex]?._id !== idSummaries,
              }
            : {}),
        }) as StructureItemTable,
    ),
  };

  let customerInvoiceColumns: MUIDataTableColumnDef[] =
    customerInvoiceTableStructure.items
      .map((item) =>
        BuilderColumnOptionsSingle({
          data: customerInvoicesWithSummaries,
          disabled: true,
          item,
        }),
      )
      .filter((column: any) => useColumns.includes(column.name));

  const isFetching = isLoading || isLoadingCompany || isCustomerInvoicesLoading;

  if (label) {
    return (
      <SectionForTableWithToolbar label={label} isLoading={isLoading}>
        <MuiDataTableWrapper
          data={customerInvoicesWithSummaries || []}
          columns={customerInvoiceColumns}
          isFetching={isFetching}
          options={options}
          tableType="main-with-filter"
        />
      </SectionForTableWithToolbar>
    );
  }

  return (
    <MuiDataTableWrapper
      data={customerInvoicesWithSummaries || []}
      columns={customerInvoiceColumns}
      isFetching={isFetching}
      options={options}
      tableType="main-with-filter"
      noBackground={true}
    />
  );
};

export default CustomerInvoiceTable;
