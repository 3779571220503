/**
 *Created by Mikael Lindahl on 2023-06-29
 */

import NoPermissionDialog from "../Dialogs/NoPermissionDialog";
import React from "react";
import {
  AccessPermissionType,
  CheckAccessPermissions,
} from "src/accurasee-backend-types/app/access_role_permission/access_role_permission.types";
import { IconButtonProps } from "@mui/material/IconButton/IconButton";
import IconButton from "@mui/material/IconButton";
import YesNoDialog from "../Dialogs/YesNoDialog";
import useTranslation from "src/hooks/useTranslationWrapper";

export type IconButtonCoreProps = IconButtonProps & {
  permissionType?: AccessPermissionType;
  permissions?: Partial<CheckAccessPermissions>;
  useDeleteDialog?: boolean;
};

const IconButtonCore = ({
  onClick,
  permissions,
  permissionType,
  useDeleteDialog,
  ...props
}: IconButtonCoreProps) => {
  const [t] = useTranslation();
  const [openNoPermissionDialog, setOpenNoPermissionDialog] =
    React.useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const hasPermission =
    permissions && permissionType ? permissions[permissionType] : true;

  const _onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!hasPermission) {
      setOpenNoPermissionDialog(true);
    } else if (onClick) {
      onClick(e);
    }
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          if (useDeleteDialog) {
            setOpenDeleteDialog(true);
          } else {
            _onClick(e);
          }
        }}
        {...props}
      >
        {props.children}
      </IconButton>
      <NoPermissionDialog
        open={openNoPermissionDialog && !!permissionType}
        onClose={() => setOpenNoPermissionDialog(false)}
        permissionType={permissionType}
      />
      <YesNoDialog
        open={!!useDeleteDialog && openDeleteDialog}
        onNo={() => setOpenDeleteDialog(false)}
        onYes={(e) => {
          _onClick(e);
          setOpenDeleteDialog(false);
        }}
      >
        {t("Are you sure you want to delete this item?")}
      </YesNoDialog>
    </>
  );
};

export default IconButtonCore;
