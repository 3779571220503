/**
 *Created by Mikael Lindahl on 2023-02-21
 */

import Box from "@mui/material/Box";
import BuilderColumnOptionsSingle from "../../../Builders/Table/BuilderColumnOptionsSingle";
import InvoicesTotals from "../../../Complex/Tables/InvoicesTotals";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Section from "../../../Basic/Simple/Sections/Section";
import useFormTable from "../../../../hooks/useFormTable";
import { FormGlue } from "src/hooks/useFormGlue";
import { Grid } from "@mui/material";
import { MUIDataTableColumnDef } from "mui-datatables";
import { addRow } from "../utils/PlannedInvoiceRowUtils";
import { useGetCustomerQuery } from "src/redux/services/CustomerService";
import { useMUIDataTableOptions } from "../../../../hooks/useMUIDataTableOptions";
import {
  getStructure,
  GetStructureExtraProps,
} from "./PlannedInvoiceRowsTableStructure";
import {
  PlannedInvoiceRow,
  PlannedInvoiceWithPermissions,
} from "../../../../accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import ButtonAdd from "../../../Basic/Simple/Buttons/ButtonAdd";
import useGetArticles from "../../../../hooks/useGetArticles";

export type PlannedInvoiceRowExtension = {
  rowNumber?: number;
};

export type PlannedInvoiceRowExtended = PlannedInvoiceRow &
  PlannedInvoiceRowExtension;

type PlannedInvoiceRowsRewriteTProps = {
  formGlue?: FormGlue<PlannedInvoiceRow[]>;
  plannedInvoice?: PlannedInvoiceWithPermissions;
};

const PlannedInvoiceRows = ({
  formGlue,
  plannedInvoice,
}: PlannedInvoiceRowsRewriteTProps) => {
  const {
    articlesWithContractArticleData,
    contract,
    isLoading: isLoadingArticles,
  } = useGetArticles(plannedInvoice?.contractId);

  const { data: customer, isLoading: isLoadingCustomer } = useGetCustomerQuery(
    plannedInvoice?.customerId,
    { skip: plannedInvoice?.customerId === undefined },
  );

  const data: PlannedInvoiceRowExtended[] =
    plannedInvoice?.invoiceRows?.map((d, index) => ({
      ...d,
      rowNumber: index + 1,
    })) || [];

  const { formData, helperText, setFormData, setHelperText, structure } =
    useFormTable<PlannedInvoiceRowExtended, GetStructureExtraProps>({
      extraProps: {
        articles: articlesWithContractArticleData,
        customer,
        plannedInvoice,
      },
      formGlue,
      initialFormData: data,
      getStructure,
    });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<PlannedInvoiceRowExtended>({
      data: formData,
      helperText,
      item,
    }),
  );

  const { options } = useMUIDataTableOptions({
    pagination: formData.length >= 10,
  });

  return (
    <Section
      label={"invoice rows"}
      md={12}
      lg={12}
      isLoading={isLoadingArticles || isLoadingCustomer}
    >
      <Grid item xs={12}>
        <MuiDataTableWrapper
          data={formData || []}
          columns={columns}
          options={options}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "2rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <ButtonAdd
          label={"Add row"}
          onClick={() =>
            addRow({
              contract,
              formData,
              helperText,
              plannedInvoice,
              setFormData,
              setHelperText,
            })
          }
        />
        <Box
          sx={{
            marginLeft: "auto",
            width: "fit-content",
          }}
        >
          {plannedInvoice && (
            <InvoicesTotals
              invoicePlan={plannedInvoice}
              invoiceRows={formData}
            />
          )}
        </Box>
      </Grid>
    </Section>
  );
};

export default PlannedInvoiceRows;
