/**
 *Created by Mikael Lindahl on 2023-11-28
 */

import useFormContainer from "../../../hooks/useFormContainer";
import getStructure from "./ContractInsightsDateFilterStructure";
import BuildItems from "../../../components/Builders/Container/BuildItems";
import { useEffect } from "react";

type FilterData = {
  startDate: Date;
  endDate: Date;
};

type ContractInsightsDateFilterProps = {
  filterData: FilterData;
  setFilterData: (data: { startDate: Date; endDate: Date }) => void;
};

const ContractInsightsDateFilter = (props: ContractInsightsDateFilterProps) => {
  const { formData, helperText, structure } = useFormContainer<FilterData>({
    getStructure,
    initialFormData: props.filterData,
  });

  useEffect(() => {
    if (
      formData !== undefined &&
      (formData.startDate !== props.filterData.startDate ||
        formData.endDate !== props.filterData.endDate)
    ) {
      props.setFilterData(formData);
    }
  }, [formData]);

  const commonPropsBuildItem: any = { data: formData, helperText };

  return BuildItems({
    items: structure.items,
    ...commonPropsBuildItem,
  });
};

export default ContractInsightsDateFilter;
