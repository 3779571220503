/**
 *Created by Mikael Lindahl on 2023-02-22
 */

import { CommonBuilderTableTypes } from "../CommonBuilderTableTypes";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import get from "../Utils/get";
import getDisabled from "../Utils/getDisabled";
import getHelperText from "../Utils/getHelperText";
import getIsInActiveWarning from "src/utils/getIsInActiveWarning";
import getOptions from "../Utils/getOptions";
import getSelectedOptions from "../Utils/getSelectedOptions";
import setValue from "../Utils/setValue";
import TextField from "@mui/material/TextField";
import useStyles from "../BuildColumnItemsStyles";

type AutocompleteColumnItemTProps = CommonBuilderTableTypes;

const AutocompleteColumnItem: (
  props: AutocompleteColumnItemTProps,
) => JSX.Element = (props) => {
  const classes = useStyles();

  const commonCallbackProps = {
    item: props.item,
    data: props.data,
    rowIndex: props.rowIndex,
  };

  const getHelperTextProps = {
    item: props.item,
    helperText: props.helperText,
    rowIndex: props.rowIndex,
  };

  const itemDisabled = props.disabled || getDisabled(commonCallbackProps);

  const options = getOptions(commonCallbackProps) || [];
  const optionMaxLength = Math.max(...options.map((o) => o.label.length));
  // Douple size if it is multiple item
  const width =
    Math.min(60 + optionMaxLength * 10, 400) *
    (props.item?.selectionOptions?.multiple ? 2 : 1);

  const selectedOptions = getSelectedOptions(commonCallbackProps);

  const value = !selectedOptions
    ? null
    : props.item?.selectionOptions?.multiple
      ? selectedOptions
      : selectedOptions[0] || null;

  return (
    <Autocomplete
      // Note: Do not use disablePortal since it removes the border frame
      // from Autocomplete when displayed in modal
      size="small"
      disabled={itemDisabled}
      id={props.item.dataName as string}
      // Added this since sometimes get error even though options exists in list
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={options}
      style={{ width, ...get("sx", commonCallbackProps) }}
      classes={{ paper: classes.paper }}
      limitTags={2}
      multiple={props.item?.selectionOptions?.multiple}
      // if item?.selectionOptions?.multiple then value should be array otherwise string for single selector
      value={value}
      getOptionDisabled={(option) => !!option.disabled}
      getOptionLabel={(value) => {
        let label = typeof value === "object" ? value.label : value;
        return label === null ? "" : label;
      }}
      onChange={(_, value) => {
        const newValue = Array.isArray(value)
          ? value?.map((v) => v?.value)
          : value?.value;

        let changeProps = { ...commonCallbackProps, value: newValue };

        if (props.item.setFormDataCustom) {
          props.item.setFormDataCustom(changeProps);
        } else {
          setValue(changeProps);
        }
      }}
      // Needed in order to allow for select options with same label
      renderOption={(props, item) => {
        return (
          <Box
            component="i"
            {...props}
            key={props.id}
            sx={{
              width,
              color: !item.isActive ? "var(--gray-3)" : "inherit",
              ...get("sx", commonCallbackProps),
            }}
          >
            {item.label}
          </Box>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            sx={{
              width: "100%",
              ...(selectedOptions?.some((o) => o.isActive === false) && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "var(--warning)", // Set your desired color here
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--warning)", // Color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--warning)", // Color when focused
                  },
                },
                // Apply color to the helper text
              }),
            }}
            helperText={
              selectedOptions?.some((o) => o.isActive === false)
                ? getIsInActiveWarning(selectedOptions)
                : getHelperText(getHelperTextProps)
            }
            error={Boolean(getHelperText(getHelperTextProps))}
            {...params}
          />
        );
      }}
    />
  );
};

export default AutocompleteColumnItem;
