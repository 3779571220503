/**
 *Created by Mikael Lindahl on 2024-06-04
 */

import { CollectionName } from "src/accurasee-backend-types/app/general/collection.types";
import { MicrosoftDriveData } from "src/hooks/useMicrosoftDrive/useMicrosoftDriveList";
import { MicrosoftTemplatePopulateData } from "src/context/MicrosoftDriveProvider/Lib/MicrosoftDriveService";
import { useState } from "react";
import ButtonPrimary from "../../Simple/Buttons/ButtonPrimary";
import DialogMicrosoftDriveCreateFileFromTemplate from "../Dialogs/DialogMicrosoftDriveCreateFileFromTemplate";
import useTranslation from "src/hooks/useTranslationWrapper";

type ButtonMicrosoftDriveCreateFileFromTemplateProps = {
  allFiles: MicrosoftDriveData[];
  collectionName: CollectionName;
  dataPopulate?: MicrosoftTemplatePopulateData;
  leafFolder?: string;
  invalidate: () => void;
  prefixDocumentName?: string;
};

const ButtonMicrosoftDriveCreateFileFromTemplate = (
  props: ButtonMicrosoftDriveCreateFileFromTemplateProps,
) => {
  const [t] = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonPrimary onClick={() => setOpen(true)}>
        {t("Create from template")}
      </ButtonPrimary>
      <DialogMicrosoftDriveCreateFileFromTemplate
        onClose={() => setOpen(false)}
        open={open}
        {...props}
      />
    </>
  );
};

export default ButtonMicrosoftDriveCreateFileFromTemplate;
