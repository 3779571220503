import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";

const getStructure: UseFormContainerGetStructure<{
  bookingDate: Date;
}> = ({ setFormData }) => {
  const structure: StructureContainer<{ bookingDate: Date }> = {
    items: [
      {
        type: "date",
        dataName: "bookingDate",
        label: "Date",
        setFormData,
      },
    ],
  };

  return structure;
};

export default getStructure;
