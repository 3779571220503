import { isNumber } from "lodash";
import { MUIDataTableColumn, MUIDataTableState } from "mui-datatables";
import getValue from "src/components/Builders/Container/Utils/getValue";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { StructureContainer } from "../../Builders/Container/CommonBuilderContainerTypes";
import { minMaxValue } from "./FilterCreateContainer";

export type ItemsTypes = "date" | "number";

export type GetStructureExtraProps = {
  column: MUIDataTableColumn;
  filterList: MUIDataTableState["filterList"];
  filterName: string;
  index: number;
  onChange: (
    val: string | string[],
    index: number,
    column: MUIDataTableColumn,
  ) => void;
  type: "date" | "number";
};

function isDate(dateString: string | undefined) {
  if (!dateString) return false;
  const date = new Date(dateString);
  return !isNaN(date.valueOf()) || date instanceof Date;
}

function isDateOrEmptyString(dateString: string | undefined) {
  if (!dateString) return true;
  return isDate(dateString);
}

export const getStructure: UseFormContainerGetStructure<
  minMaxValue,
  GetStructureExtraProps,
  ItemsTypes
> = ({ setFormData, extraProps }) => {
  const sizes = { xl: 6, lg: 6 };
  const minError = "Min value needs to be smaller than max value";
  const maxError = "Max value needs to be larger than min value";
  const needsToBeNumberError = "Value needs to be number";
  const needsToBeDateError = "Value needs to be a date";

  const structure: StructureContainer<minMaxValue, ItemsTypes> = {
    label: "Filter label",
    subLabel: "Filter sub label",
    items: [
      {
        itemType: "number",
        gridProps: sizes,
        validate: (props) => {
          if (extraProps?.type === "date") return true;
          const value = getValue(props);
          const checkNumber = !(value && !isNumber(value));
          const smallerThanMax = props.data?.maxNumber
            ? props.data?.maxNumber >= value
            : true;
          return checkNumber && smallerThanMax;
        },
        getErrorText: (props) => {
          const { data } = props;
          if (
            data?.minNumber &&
            data?.maxNumber &&
            data?.minNumber > data?.maxNumber
          ) {
            return minError;
          } else if (!(data?.minNumber && !isNumber(data.minNumber))) {
            return needsToBeNumberError;
          } else return "";
        },
        required: false,
        type: "number",
        dataName: "minNumber",
        label: "Min",
        setFormData,
      },
      {
        itemType: "number",
        gridProps: sizes,
        validate: (props) => {
          if (extraProps?.type === "date") return true;
          const value = getValue(props);
          const checkNumber = !(value && !isNumber(value));
          const biggerThanMin = props.data?.minNumber
            ? props.data?.minNumber <= value
            : true;

          return checkNumber && biggerThanMin;
        },
        getErrorText: (props) => {
          const { data } = props;
          if (
            data?.minNumber &&
            data?.maxNumber &&
            data?.minNumber > data?.maxNumber
          ) {
            return maxError;
          } else if (!(data?.minNumber && !isNumber(data.minNumber))) {
            return needsToBeNumberError;
          } else return "";
        },
        required: false,
        type: "number",
        dataName: "maxNumber",
        label: "Max",
        setFormData,
      },
      {
        itemType: "date",
        gridProps: sizes,
        validate: (props) => {
          if (extraProps?.type === "number") return true;
          const value = getValue(props);
          const isValDate = isDateOrEmptyString(value);
          const smallerThanMax =
            isDate(props.data?.maxDate) && isDate(value)
              ? new Date(props.data?.maxDate!) >= new Date(value)
              : true;
          return isValDate && smallerThanMax;
        },
        getErrorText: (props) => {
          const { data } = props;
          if (
            isDate(data?.minDate) &&
            isDate(data?.maxDate) &&
            new Date(data?.minDate!) >= new Date(data?.maxDate!)
          ) {
            return minError;
          } else if (isDate(data?.minDate)) {
            return needsToBeDateError;
          } else return "";
        },
        required: false,
        type: "date",
        dataName: "minDate",
        label: "Min",
        setFormData,
      },
      {
        itemType: "date",
        gridProps: sizes,
        validate: (props) => {
          if (extraProps?.type === "number") return true;
          const value = getValue(props);
          const isValDate = isDateOrEmptyString(value);
          const biggerThanMin =
            isDate(props.data?.minDate) && isDate(value)
              ? new Date(props.data?.minDate!) <= new Date(value)
              : true;
          return isValDate && biggerThanMin;
        },
        getErrorText: (props) => {
          const { data } = props;
          if (
            isDate(data?.minDate) &&
            isDate(data?.maxDate) &&
            new Date(data?.minDate!) >= new Date(data?.maxDate!)
          ) {
            return maxError;
          } else if (isDate(data?.minDate)) {
            return needsToBeDateError;
          } else return "";
        },
        required: false,
        type: "date",
        dataName: "maxDate",
        label: "Max",
        setFormData,
      },
    ],
  };
  return structure;
};
