import {
  ReportGetResponseColumn,
  ReportGetResponseData,
} from "src/accurasee-backend-types/app/report/report.types";
import { backendApi } from "../api";
import { docListTags } from "./utils";

const CACHE_TYPE = "Reports";

export const reportsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getReports: build.query<
      {
        columns: ReportGetResponseColumn[];
        data: ReportGetResponseData[];
        metadata: any;
      },
      any
    >({
      query: (params) => ({ url: "/reports", params }),
      providesTags: docListTags(CACHE_TYPE),
    }),
  }),
  overrideExisting: false,
});

export const { useGetReportsQuery } = reportsApi;

export default reportsApi;
