import i18next from "i18next";

const getGroupDelimiter = (currencyCode: string | undefined): string => {
  const locale = currencyCode === "SEK" ? "sv-SE" : i18next.language || "sv-SE";

  const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
  return group;
};

export default getGroupDelimiter;
