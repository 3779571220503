import { DocumentHistoryAddOn } from "./history.types";
import { Types } from "mongoose";

export interface GlobalType extends DocumentHistoryAddOn {
  _id?: Types.ObjectId;
  createdAt?: Date;
  updatedAt?: Date;
}

export const VATType = [
  "Reversed construction VAT liability",
  "Regular VAT",
  "Reversed EU VAT liability",
  "International customer, no VAT",
  "Reversed EU tax liability",
] as const;

export type VATTypeType = (typeof VATType)[number];

export const customerStatusValues = [
  "draft",
  "sent",
  "approved",
  "declined",
  "resent",
  "cancelled",
] as const;
export type CustomerStatus = (typeof customerStatusValues)[number];
