import { Box } from "@mui/material";
import { getStructureFilter } from "./InsightReportStructure";
import { ReportGetRequestQuery } from "src/accurasee-backend-types/app/report/report.types";
import { useContext, useEffect } from "react";
import { useGetReportsQuery } from "src/redux/services/ReportService";
import { useParams } from "react-router-dom";
import { UserStateContext } from "src/context/UserStateProvider";
import BoxHeading from "src/components/Basic/Mixed/Headings/BoxHeading";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonSecondary from "../../../components/Basic/Simple/Buttons/ButtonSecondary";
import clone from "src/utils/clone";
import InsightReportsTable from "./InsightReportsTable";
import LinearProgress from "@mui/material/LinearProgress";
import toDay from "src/utils/date";
import useFormContainer from "src/hooks/useFormContainer";
import useTranslation from "src/hooks/useTranslationWrapper";

const InsightReport = ({
  reportTemplateId,
  reportTemplateName,
  baseFilter,
}: {
  reportTemplateId?: string;
  reportTemplateName: string;
  baseFilter: Partial<ReportGetRequestQuery>;
}) => {
  const [t] = useTranslation();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const today = new Date();

  const { contractId } = useParams<{ contractId: string }>();

  const { userState, setUserState } = useContext(UserStateContext);

  const { formData, helperText, setFormData, structure } = useFormContainer<
    Partial<ReportGetRequestQuery>
  >({
    getStructure: getStructureFilter,
    initialFormData: {
      ...baseFilter,
      ...(contractId
        ? userState?.reportFilter?.contract &&
          userState?.reportFilter?.contract?.hasOwnProperty(contractId)
          ? { ...userState?.reportFilter?.contract[contractId] }
          : {}
        : { ...userState?.reportFilter?.main }),
    },
  });

  const filter: any = {
    ...(reportTemplateId ? { reportTemplateId } : {}),
    isTranslated: true,
    startDate: toDay(formData?.startDate),
    endDate: toDay(formData?.endDate),
    resolution: formData?.resolution,
    ...(contractId ? { contractId } : {}),
  };
  const { data: dataQuery, isLoading, isFetching } = useGetReportsQuery(filter);

  const reports = dataQuery?.data || [];
  const columnsServerSide = dataQuery?.columns || [];

  useEffect(() => {
    if (formData) {
      const newState = clone(userState);
      newState.reportFilter = {
        ...userState?.reportFilter,
        ...(contractId
          ? {
              contract: {
                ...userState?.reportFilter?.contract,
                [contractId]: {
                  ...formData,
                  startDate: toDay(formData.startDate),
                  endDate: toDay(formData.endDate),
                },
              },
            }
          : {
              main: {
                ...userState?.reportFilter?.main,
                ...formData,
                startDate: toDay(formData.startDate),
                endDate: toDay(formData.endDate),
              },
            }),
      };
      setUserState(newState);
    }
  }, [formData]);

  const commonPropsBuildItem = { data: formData, helperText };

  const key = JSON.stringify(filter);

  return (
    <>
      <BoxHeading label={reportTemplateName} />
      <Box sx={{ display: "inline-flex", columnGap: "var(--space-8px)" }}>
        {BuildItems({
          items: structure.items,
          ...commonPropsBuildItem,
        })}
      </Box>
      <Box
        sx={{ display: "flex", columnGap: "var(--space-8px)" }}
        marginTop={3}
      >
        <ButtonSecondary
          onClick={() => {
            // startOfMonth
            const startDate = new Date(currentYear, currentMonth - 6);
            // endOfMonth
            const endDate = new Date(currentYear, currentMonth + 1, 0);

            setFormData({
              ...formData,
              startDate,
              endDate,
            });
          }}
        >
          {t("Last 6 months")}
        </ButtonSecondary>
        <ButtonSecondary
          onClick={() => {
            const startDate = new Date(currentYear, 0);
            setFormData({
              ...formData,
              startDate,
              endDate: today,
            });
          }}
        >
          {t("Year to date")}
        </ButtonSecondary>
      </Box>
      {(isLoading || isFetching) && formData ? (
        <LinearProgress />
      ) : (
        <InsightReportsTable
          key={key}
          columnsServerSide={columnsServerSide}
          reports={reports}
        />
      )}
    </>
  );
};

export default InsightReport;
