// @ts-nocheck
import JsExcelTemplate from "excel-template-generator";
import DocumentHandler from "./DocumentHandler";

const PizZip = require("pizzip");
const Docxtemplater = require("docxtemplater");

export default class ExcelHandler extends DocumentHandler {
  public generateDocument(
    content: ArrayBuffer | string,
    data: any,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      JsExcelTemplate.fromArrayBuffer(content)
        .then((excelTemplate: any) => {
          excelTemplate.setTag("<", ">");
          for (let key in data) {
            excelTemplate.set(key, data[key]);
          }
          resolve(excelTemplate.toBlob());
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
