/**
 *Created by Mikael Lindahl on 2023-02-23
 */

import { InvoicePlanRow } from "../../../../accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { PlannedInvoiceRowExtended } from "../PlannedInvoiceRows/PlannedInvoiceRowsTable";
import { GetSingleContractWithPermissions } from "../../../../accurasee-backend-types/app/contracts/contract.types";
import { PlannedInvoice } from "../../../../accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";

type AddRowTProps = {
  contract?: GetSingleContractWithPermissions;
  formData: PlannedInvoiceRowExtended[];
  helperText: any[];
  plannedInvoice?: PlannedInvoice;
  setHelperText: (data: any) => void;
  setFormData: (data: PlannedInvoiceRowExtended[]) => void;
};

export const addRow = (props: AddRowTProps) => {
  let row: PlannedInvoiceRowExtended = {
    discount: 0,
    houseWork: false,
    houseWorkType: "None",
    houseWorkHours: 0,
    // isIndex:props.plannedInvoice?.inde
    itemDescription: "",
    percentVAT: 0,
    isIndex: false,
    isPricePerUnitEstimate: false,
    isQuantityEstimate: false,
    rowNumber: props.formData.length + 1,
    status: "upcoming",
    textRow: true,
    total: 0,
    totalExcludingVAT: 0,
  };

  props.setHelperText([...props.helperText, {}]);
  props.setFormData([...props.formData, row]);
};

export const formDataToRows = (formData: PlannedInvoiceRowExtended[]) => {
  let data = formData?.map((d, index) => {
    delete d.rowNumber;
    for (let key in d) {
      if (d[key] === undefined) {
        delete d[key];
      }
    }

    return d;
  });
  return data as InvoicePlanRow[];
};
