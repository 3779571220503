/**
 *Created by Mikael Lindahl on 2023-02-23
 */

import {
  InvoicePlan,
  InvoicePlanRow,
} from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { EUCOUNTRIES, EUCOUNTRIESWITHSWEDEN } from "src/constants/EuCountries";
import { InvoicePlanRowExtended } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import getPriceTotalExcludingVatAndTotal from "src/utils/getPriceTotalExcludingVatAndTotal";
import { GetSingleContractWithPermissions } from "src/accurasee-backend-types/app/contracts/contract.types";
import cloneDeep from "lodash/cloneDeep";

type AddRowTProps = {
  contract: GetSingleContractWithPermissions | undefined;
  customer: Customer | undefined;
  formData: InvoicePlanRowExtended[];
  helperText: any[];
  invoicePlan?: InvoicePlan | undefined;
  setHelperText: (data: any) => void;
  setFormData: (data: InvoicePlanRowExtended[]) => void;
};

export const addRow = (props: AddRowTProps) => {
  const defaultArticle = props.contract?.contractArticles?.reduce(
    (article: ContractArticle | undefined, c) => {
      return c.defaultArticle ? c : article;
    },
    undefined,
  );

  const isMilestone = props.invoicePlan?.type === "milestone";
  const isSingleInvoice = props.invoicePlan?.type === "singleinvoice";

  let row: InvoicePlanRowExtended = {
    articleId: defaultArticle?.articleId,
    articleNumber: defaultArticle?.number,
    discount: 0,
    estimatedInvoiceDate: new Date(), //moment().format(_c.DATE_FORMAT),
    houseWork: false,
    houseWorkType: "None",
    houseWorkHours: 0,
    itemDescription: isMilestone ? "" : defaultArticle?.name || "",
    percentVAT: calculateVat(defaultArticle?.vat!, props.customer) || 0,
    isIndex:
      defaultArticle !== undefined
        ? !!defaultArticle.isIndex
        : !!props?.invoicePlan?.indexOption?.index,
    isPeriodic: false,
    isPricePerUnitEstimate:
      isMilestone || isSingleInvoice
        ? false
        : defaultArticle?.isPricePerUnitEstimate || false,
    isQuantityEstimate:
      isMilestone || isSingleInvoice
        ? false
        : defaultArticle?.isQuantityEstimate || false,
    pricePerUnit: defaultArticle?.price || 0,
    rowNumber: props.formData.length + 1,
    quantity: isMilestone ? 1 : defaultArticle?.quantity || 0,
    status: isMilestone ? "upcoming" : undefined,
    textRow: false,
    total: 0,
    totalExcludingVAT: 0,
    unitId: defaultArticle?.unitId,
    unitExternalId: defaultArticle?.unitExternalId,
  };
  if (defaultArticle) {
    const dep = calculateDependable({
      data: row,
    });

    row = { ...row, ...dep };
  }

  props.setHelperText([...props.helperText, {}]);
  props.setFormData([...props.formData, row]);
};

export const calculateDependable = (props: {
  data: InvoicePlanRowExtended;
}) => {
  const { totalExcludingVAT, total } = getPriceTotalExcludingVatAndTotal({
    quantity: props.data.quantity || 0,
    pricePerUnit: props.data.pricePerUnit || 0,
    percentVAT: props.data.percentVAT,
    discount: props.data.discount || 0,
  });

  const dependable: Partial<InvoicePlanRowExtended> = {
    total,
    totalExcludingVAT,
  };

  return dependable;
};

export const calculateVat = (vat: number, customer?: Customer) => {
  let country = customer?.countryCode;
  if (!customer) {
    console.error("Customer data not found");
    return vat;
  } else if (!country) {
    console.error("Country code not added to Customer");
    country = "SE";
  }

  if (
    (country === "SE" && customer.reversedConstructionVAT) ||
    (EUCOUNTRIES.includes(country) && customer.customerType === "COMPANY") ||
    !EUCOUNTRIESWITHSWEDEN.includes(country)
  ) {
    return 0;
  } else {
    return vat;
  }
};

export const toSubmitData = ({ data }: { data?: InvoicePlanRowExtended[] }) => {
  let submitData = cloneDeep(data);

  submitData = submitData?.map((d, index) => {
    delete d.rowNumber;
    d.status = d.status || "upcoming";
    for (let key in d) {
      if (d[key] === undefined || d[key] === null) {
        delete d[key];
      }
    }
    return d;
  });
  return submitData as InvoicePlanRow[];
};

export const toData = (data?: InvoicePlanRow[]) =>
  (data?.map((d, index) => ({
    ...d,
    rowNumber: index + 1,
    // status: d.status || "upcoming",
  })) || []) as InvoicePlanRowExtended[];
