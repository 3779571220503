import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { Unit } from "src/accurasee-backend-types/app/unit/unit.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import clone from "src/utils/clone";
import toUpdateData from "src/utils/toUpdateData";
import getSelectOptions from "../../../../../utils/getSelectOptions";

export const articleTypeOptions = [
  {
    value: "STOCK",
    label: "Stock",
  },
  {
    value: "SERVICE",
    label: "Service",
  },
];

const houseWorkTypeToLabel = {
  None: "None",
  RotConstructionWork: "Construction",
  RotElectricalWork: "Electrical",
  RotGlassSheetMetalWork: "Glass/metal",
  RotGroundWork: "Ground",
  RotBrickWork: "Brick",
  RotPaintDecorateWork: "Paint/decoration",
  RotVVS: "HVAC and plumbing",
  RutCleanJobWork: "Cleaning",
  RutCareClothTextile: "Textile care",
  RutCook: "Cooking",
  RutSnowRemove: "Snow removal",
  RutGarden: "Gardening",
  RutBabySitt: "Baby sitting",
  RutOtherCare: "Personal care",
  RutHouseWorkHelp: "House work",
  RutRemovalServices: "Removal services",
  RutITServices: "IT service",
  RutHomeAppliances: "Home appliances",
  RutFurnishing: "Furnishing",
  RutTransport: "Transportation",
  RutWashing: "Washing",
};
export const houseWorkTypeOptions = Object.entries(houseWorkTypeToLabel)
  .map(([value, label]) => ({ value, label }))
  .sort((a, b) => (a.label < b.label ? -1 : 1));

export type GetStructureExtraProps = {
  units: Unit[];
};

export const getStructure: UseFormContainerGetStructure<
  Partial<Article>,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  const structure: StructureContainer<Partial<Article>> = {
    items: [
      {
        gridProps: { xs: 6 },
        required: true,
        type: "text_input",
        dataName: "number",
        label: "Number",
        setFormData,
      },
      {
        gridProps: { xs: 6 },
        required: true,
        type: "text_input",
        dataName: "name",
        label: "Name",
        setFormData,
      },
      {
        gridProps: { xs: 6 },
        type: "autocomplete",
        required: true,
        dataName: "unitId",
        label: "Unit",
        options: getSelectOptions({
          data: extraProps?.units,
          label: "name",
          value: "_id",
        }),
        setFormData,
      },
      {
        gridProps: { xs: 6 },
        type: "selector",
        required: true,
        dataName: "vat",
        label: "VAT",
        options: getSelectOptions({
          data: [0, 0.06, 0.12, 0.25].map((value) => ({ value })),
          label: ({ value }) => `${value * 100}%`,
          value: ({ value }) => `${value}`,
        }),
        setFormData,
      },
      {
        gridProps: { xs: 6 },
        required: true,
        type: "number_input_currency",
        currencyCode: "SEK",
        dataName: "salesPrice",
        label: "Sales Price",
        setFormData,
      },
      {
        gridProps: { xs: 6 },
        required: true,
        type: "selector",
        dataName: "type",
        label: "Type",
        options: getSelectOptions({
          data: articleTypeOptions,
        }),
        setFormData,
      },
      {
        gridProps: { xs: 6 },
        type: "switch",
        dataName: "active",
        label: "Active",
        setFormData,
      },
      {
        gridProps: { xs: 6 },
        type: "switch",
        dataName: "houseWork",
        label: "House Work",
        setFormData,
      },
      {
        gridProps: { xs: 6 },
        type: "selector",
        required: true,
        dataName: "houseWorkType",
        label: "House Work Type",
        options: getSelectOptions({
          data: houseWorkTypeOptions,
        }),
        showWhen: ({ data }) => !!data?.houseWork,
        setFormData,
      },
    ],
  };

  return structure;
};

export const toSubmitData = ({
  data,
  initData,
}: {
  data?: Partial<Article>;
  initData?: Partial<Article>;
}) => {
  if (!data) {
    return;
  }

  let newData = clone(data);

  let partialData: Partial<Article>;

  if (newData._id) {
    partialData = toUpdateData<Partial<Article>>({
      data: newData,
      initData,
    });
  } else {
    partialData = newData;
  }

  return partialData;
};
