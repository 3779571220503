/**
 *Created by Mikael Lindahl on 2023-03-21
 */

import _c from "src/constants/Constants";
import {
  Contract,
  DimensionItemContract,
} from "src/accurasee-backend-types/app/contracts/contract.types";
import {
  CancellationTerms,
  ContractArticle,
  ContractType,
} from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import {
  StructureContainer,
  VatTypeTooltip,
} from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { ContactPerson } from "src/accurasee-backend-types/app/contact_person/contact_person.types";
import { Currency } from "src/accurasee-backend-types/app/currency/currency.types";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { CustomField } from "src/accurasee-backend-types/app/custom_field/custom_field.types";
import { dicToList, listToDic } from "src/utils/transform";
import { DimensionExtra } from "src/redux/services/DimensionService";
import { Index } from "src/accurasee-backend-types/app/index/index.types";
import { lessOrEqualThan, lessThan, setFormDataDatePair } from "src/utils/date";
import { Project } from "src/accurasee-backend-types/app/project/project.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { subDays } from "date-fns";
import { TermsOfPayment } from "src/accurasee-backend-types/app/termsofpayment/termsofpayment.types";
import { TFunction } from "src/hooks/useTranslationWrapper";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import clone from "src/utils/clone";
import getDimensionItems, {
  ContractExtended,
  TmpDimensionItems,
} from "src/utils/getDimensionItems";
import getValue from "src/components/Builders/Container/Utils/getValue";
import getVatType from "src/utils/getVatType";
import moment from "moment-timezone";
import removeKeysWithEmptyStringAsValue from "src/utils/removeKeysWithEmptyStringAsValue";
import toUpdateData from "src/utils/toUpdateData";
import getSelectOptions, {
  SelectOption,
} from "../../../../../utils/getSelectOptions";
import getSelectOptionsDayMonthQuarterYear from "../../../../../utils/getSelectOptionsDayMonthQuarterYear";
import getSelectOptionsAutomaticManual from "../../../../../utils/getSelectOptionsAutomaticManual";
import getSelectOptionsDayWeekMonth from "../../../../../utils/getSelectOptionsDayWeekMonth";
import { escape } from "../../../../../utils/translate";
import getCustomerName from "../../../../../utils/getCustomerName";

export type GetStructureExtraProps = {
  contacts?: ContactPerson[];
  contractType?: ContractType;
  currencies?: Currency[];
  currentIndex?: Index;
  customers?: Customer[];
  customFields?: CustomField[];
  dimensions?: DimensionExtra[];
  indexOptions: SelectOption[];
  isContactModalOpen: boolean;
  projects?: Project[];
  plannedInvoicesCount: number;
  setContractArticles?: (v: ContractArticle[]) => void;
  setIsContactModalOpen: (v: boolean) => void;
  setVatTypeTooltipOnCustomer: (customer: Customer) => void;
  t: TFunction;
  termsOfPayments?: TermsOfPayment[];
  users?: ReturnUser[];
  vatTypeTooltip: VatTypeTooltip;
  handleProjectInputChange: (input: string) => void;
  isFetchingProjects: boolean;
};

export type ItemsTypes = "info" | "time" | "customer" | "attachment";

const mappingStatus = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
  {
    label: "Finished",
    value: "finished",
  },
  {
    label: "Invoiced",
    value: "invoiced",
  },
  {
    label: "Partially invoiced",
    value: "partiallyinvoiced",
  },
  {
    label: "Upcoming",
    value: "upcoming",
  },
];

export const addNumberOfDateUnitsToDate = (
  date: Date,
  unit: string,
  number: number,
) => {
  let updatedDate: Date;
  switch (unit) {
    case "day":
      updatedDate = addDaysToDate(new Date(date), number);
      break;
    case "week":
      updatedDate = addWeeksToDate(new Date(date), number);
      break;
    case "month":
      updatedDate = addMonthsToDate(new Date(date), number);
      break;
    case "quarter":
      updatedDate = addQuartersToDate(new Date(date), number);
      break;
    case "year":
      updatedDate = addYearsToDate(new Date(date), number);
      break;
    default:
      updatedDate = date;
      break;
  }
  return updatedDate;
};

export const getContractRenewalDate = (
  contractEndDate?: Date,
  contractCancellationTerms?: CancellationTerms,
) => {
  if (
    contractEndDate === undefined ||
    contractCancellationTerms === undefined
  ) {
    return undefined;
  }

  let renewalDate: Date = addNumberOfDateUnitsToDate(
    contractEndDate,
    contractCancellationTerms.unit,
    -contractCancellationTerms.number,
  );
  renewalDate = addDaysToDate(new Date(renewalDate), 1);
  return renewalDate;
};

export function addMonthsToDate(date: Date, months: number) {
  let newDate = moment.utc(new Date(date)).add(months, "months").toDate();
  return newDate;
}

export function addWeeksToDate(date: Date, weeksToAdd: number) {
  return addDaysToDate(date, weeksToAdd * 7);
}

export function addDaysToDate(date: Date, daysToAdd: number) {
  let newDate = moment.utc(new Date(date)).add(daysToAdd, "days").toDate();
  return newDate;
}

export function addQuartersToDate(date: Date, quartersToAdd: number) {
  return addMonthsToDate(date, quartersToAdd * 3);
}

export function addYearsToDate(date: Date, yearsToAdd: number) {
  let newDate = moment.utc(new Date(date)).add(yearsToAdd, "years").toDate();
  return newDate;
}

const checkRenewalDate = (renewalDate: Date) => {
  const currentDate = new Date();
  const tomorrowDate = currentDate.setDate(currentDate.getDate() + 1);
  return renewalDate.getTime() < tomorrowDate ? false : true;
};

export const getStructure: UseFormContainerGetStructure<
  ContractExtended,
  GetStructureExtraProps,
  ItemsTypes
> = ({ setFormData, extraProps, t }) => {
  const dimensionItems = getDimensionItems<ContractExtended, ItemsTypes>({
    contractDimensions: extraProps?.contractType?.contractDimensions,
    dimensions: extraProps?.dimensions,
    itemType: "info",
    setFormData,
  });

  const structureFromCustomField: StructureContainer<
    Partial<ContractExtended>,
    ItemsTypes
  > = {
    items:
      extraProps?.customFields?.map((customField) => ({
        itemType: "time",
        required: customField.required,
        type: customField.fieldType === "list" ? "selector" : "text_input",
        dataName: customField.name,
        label: escape(customField?.name),
        options:
          customField.fieldType === "list"
            ? getSelectOptions({
                data: customField?.selectOptionValues?.map((value) => ({
                  label: value,
                  value,
                })),
              })
            : undefined,
        setFormData,
      })) || [],
  };

  const structure: StructureContainer<ContractExtended, ItemsTypes> = {
    allowFields: ["contractArticles"],
    items: [
      {
        itemType: "info",
        validate: (props) => {
          const value = getValue(props);
          return !(value && value.length <= 1);
        },
        getErrorText: "To short",
        required: true,
        type: "text_input",
        dataName: "name",
        label: Boolean((extraProps?.plannedInvoicesCount || 0) > 0)
          ? "Name (disabled since planned invoices exists on this project)"
          : "Name",
        disabled: Boolean((extraProps?.plannedInvoicesCount || 0) > 0),
        setFormData,
      },
      {
        itemType: "info",
        required: true,
        type: "autocomplete",
        dataName: "projectId",
        disabled: Boolean((extraProps?.plannedInvoicesCount || 0) > 0),
        label: Boolean((extraProps?.plannedInvoicesCount || 0) > 0)
          ? "Project (disabled since planned invoices exists on this project)"
          : "Project",
        options: getSelectOptions({
          data: extraProps?.projects?.map((item) => {
            return {
              label: `${item.externalId} ${item.name}`,
              value: String(item._id),
            };
          }),
        }),
        selectionOptions: {
          inputPlaceholder: "Type to search for project",
          loading: extraProps?.isFetchingProjects,
          onInputChange: (event, input: string) => {
            extraProps?.handleProjectInputChange(input);
          },
        },
        setFormDataCustom: (props) => {
          const project = extraProps?.projects?.find(
            (item) => item._id === props.value,
          );

          if (props.data) {
            const newData: Partial<ContractExtended> = {
              ...props.data,
              customerId: props.data?.customerId || project?.customerId,
              endDate: project?.endDate || props.data?.endDate || new Date(),
              name: project?.name || props.data?.name || "",
              projectId: props.value,
              projectName: project?.name || "",
              startDate:
                project?.startDate || props.data?.startDate || new Date(),
              projectExternalId: project?.externalId,
            };
            const customer = extraProps?.customers?.find(
              (item) => item._id === newData.customerId,
            );
            if (customer) {
              extraProps?.setVatTypeTooltipOnCustomer(customer);
            }
            if (project?.externalId) {
              newData.projectExternalId = project.externalId;
            }

            setFormData(newData as ContractExtended);
          }
        },
      },
      {
        itemType: "info",
        gridProps: { md: 6 },
        type: "text",
        dataName: "projectExternalId",
        label: "Contract id",
      },
      {
        itemType: "info",
        gridProps: { md: 6 },
        type: "text",
        dataName: "contractType",
        getValue: () =>
          extraProps?.contractType?.name || "No contract type used",
        label: "Based on contract type",
      },
      {
        itemType: "info",
        gridProps: { md: 12 },
        type: "autocomplete",
        dataName: "ownerIds",
        label: "Contract owner(s)",
        selectionOptions: { multiple: true },
        options: getSelectOptions({
          data: extraProps?.users,
          label: (user) => `${user.firstName} ${user.lastName}`,
          value: (user) => String(user._id),
        }),
        setFormData,
        validate: (props) => {
          const value = getValue(props);
          return value.length > 0;
        },
        getErrorText: () => "You need to select at least one owner",
      },
      {
        itemType: "info",
        gridProps: { md: 12 },
        type: "text",
        getValue: (props) =>
          t(
            mappingStatus.find((m) => m.value === props?.data?.status)?.label ||
              "unknown",
          ),
        dataName: "status",
        label: "Status",
      },
      ...dimensionItems,
      {
        itemType: "info",
        gridProps: { md: 12 },
        required: false,
        type: "number_input_currency",
        dataName: "contractFeatures.totalPrice",
        label: "Total price",
        showWhen: (props) => {
          return Boolean(props.data?.contractFeatures?.totalPrice);
        },
        setFormData,
      },
      {
        itemType: "info",
        gridProps: { xs: 4 },
        type: "switch",
        label: "Use index",
        dataName: "indexOption.index",
        setFormDataCustom: (props) => {
          let newData = clone(props.data);
          if (newData) {
            newData.indexOption = {
              index: props.value,
              indexId: undefined,
            };

            for (const contractArticle of newData?.contractArticles || []) {
              contractArticle.isIndex = props.value;
            }

            setFormData(newData);
          }
        },
      },
      {
        itemType: "info",
        gridProps: { xs: 8 },
        required: true,
        showWhen: (props) => !!props.data?.indexOption?.index,
        type: "autocomplete",
        label: "Set index to use",
        dataName: "indexOption.indexId",
        options: extraProps?.indexOptions,
        getErrorText: _c.ERROR_TEXTS.INACTIVE_INDEX,
        validate: (props) =>
          !(
            extraProps?.currentIndex?.active === false &&
            props.data?.indexOption?.indexId === extraProps?.currentIndex?._id
          ),
        setFormDataCustom: (props) => {
          let newData = clone(props.data);
          if (newData) {
            newData.indexOption = {
              ...(props.data?.indexOption || {}),
              indexId: props.value,
            };

            setFormData(newData);
          }
        },
      },
      {
        itemType: "info",
        gridProps: { xs: 4 },
        type: "switch",
        label: "BK connected",
        dataName: "contractFeatures.bkConnected",
        showWhen: (props) =>
          Boolean(extraProps?.contractType?.contractTypeFeatures?.bkConnected),
        setFormData,
      },
      {
        itemType: "info",
        gridProps: { xs: 4 },
        type: "switch",
        label: "Service order contract",
        dataName: "contractFeatures.serviceOrderContract",
        showWhen: () =>
          Boolean(
            extraProps?.contractType?.contractTypeFeatures
              ?.serviceOrderContract,
          ),
        setFormData,
      },
      {
        itemType: "attachment",
        gridProps: { md: 12 },
        type: "file",
        dataName: "files",
        label: "Attachment",
        filesAllowed: ["application/pdf"],
        setFormData,
      },
      {
        itemType: "time",
        gridProps: { md: 6 },
        type: "date",
        dataName: "startDate",
        disabled: (extraProps?.plannedInvoicesCount || 0) > 0,
        label: "Start date",
        validate: (props) =>
          lessOrEqualThan(props.data?.startDate, props.data?.endDate),
        getErrorText: "Start date can not come after end date",
        setFormDataCustom: ({ data, value }) => {
          setFormDataDatePair({
            data,
            otherKey: "endDate",
            otherAction: "to_end_of_month",
            value,
            valueKey: "startDate",
            setFormData,
          });
        },
      },
      {
        itemType: "time",
        gridProps: { md: 6 },
        type: "date",
        dataName: "endDate",
        label: "End date",
        validate: (props) => {
          if (!props.data?.endDate || !props.data?.cancellationTerms) {
            return true;
          }
          if (props.data?.renewalTerms?.renewalType === "automatic") {
            // return true;
            const renewalDate =
              getContractRenewalDate(
                props.data?.endDate,
                props.data?.cancellationTerms,
              ) || subDays(new Date(), 1);

            return checkRenewalDate(renewalDate);
          } else {
            return lessOrEqualThan(props.data?.startDate, props.data?.endDate);
          }
        },
        getErrorTextNoTranslation: true,
        getErrorText: (props) => {
          if (props.data?.endDate && props.data?.cancellationTerms) {
            let cutOffDate: Date = addNumberOfDateUnitsToDate(
              new Date(),
              props.data?.cancellationTerms.unit,
              props.data?.cancellationTerms.number,
            );

            const formatDate = moment(cutOffDate.toString()).format(
              _c.DATE_FORMAT,
            );

            return `${extraProps?.t(
              "End date needs to come after",
            )} ${formatDate.slice(0, 10)} ${extraProps?.t(
              "(determined by cancellation terms)",
            )}`;
          } else if (lessThan(props.data?.endDate, props.data?.startDate)) {
            return (
              extraProps?.t("End date can not come before start date") || ""
            );
          } else {
            return "";
          }
        },
        setFormDataCustom: ({ data, value }) => {
          setFormDataDatePair({
            data,
            otherKey: "startDate",
            otherAction: "to_start_of_month",
            value,
            valueKey: "endDate",
            setFormData,
          });
        },
      },
      {
        itemType: "time",
        required: true,
        type: "selector",
        dataName: "renewalTerms.renewalType",
        label: "Renewal",
        options: getSelectOptionsAutomaticManual(t),
        setFormDataCustom: (props) => {
          if (props.data && props.value === "automatic") {
            const newData: ContractExtended = {
              ...props.data,
              cancellationTerms: {
                number: 1,
                unit: "month",
              },
              renewalTerms: {
                number: 1,
                unit: "month",
                renewalType: props.value,
              },
            };
            setFormData(newData);
          } else if (props.data) {
            const newData: ContractExtended = {
              ...props.data,
              cancellationTerms: undefined,
              renewalTerms: {
                renewalType: props.value,
              },
            };
            setFormData(newData);
          }
        },
      },
      {
        itemType: "time",
        gridProps: { md: 6 },
        type: "object",
        required: true,
        label: "Cancellation terms",
        showWhen: (props) => props.data?.renewalTerms?.renewalType !== "manual",
        items: [
          {
            gridProps: { md: 4 },
            required: true,
            type: "number",
            dataName: "cancellationTerms.number",
            setFormData,
          },
          {
            gridProps: { md: 8 },
            required: true,
            type: "selector",
            dataName: "cancellationTerms.unit",
            options: getSelectOptionsDayMonthQuarterYear(t),
            setFormData,
          },
        ],
      },
      {
        itemType: "time",
        gridProps: { md: 6 },
        type: "object",
        required: true,
        label: "Automatic renewal",
        showWhen: ({ data }) => data?.renewalTerms?.renewalType === "automatic",
        items: [
          {
            gridProps: { md: 4 },
            required: (props) =>
              props.data?.renewalTerms?.renewalType === "automatic",
            type: "number",
            dataName: "renewalTerms.number",
            setFormData,
          },
          {
            gridProps: { md: 8 },
            required: (props) =>
              props.data?.renewalTerms?.renewalType === "automatic",
            type: "selector",
            dataName: "renewalTerms.unit",
            setFormData,
            options: getSelectOptionsDayMonthQuarterYear(t),
          },
        ],
      },
      {
        itemType: "time",
        gridProps: { md: 6 },
        type: "object",
        dataName: "endDateReminder",
        label: "Email reminder before end date",
        showWhen: (props) =>
          Boolean(
            extraProps?.contractType?.contractTypeFeatures?.endDateReminder,
          ) && props.data?.renewalTerms?.renewalType === "manual",
        items: [
          {
            gridProps: { xs: 4 },
            type: "number",
            dataName: "endDateReminder.number",
            setFormData,
            validate: (props) => {
              if (props.data?.endDateReminder?.number) {
                return props.data?.endDateReminder?.number > 0;
              } else {
                return false;
              }
            },
            getErrorText: "Must be grater than zero",
          },
          {
            gridProps: { xs: 8 },
            type: "selector",
            dataName: "endDateReminder.unit",
            options: getSelectOptionsDayWeekMonth(t),
            setFormData,
          },
        ],
      },
      ...structureFromCustomField.items,
      {
        itemType: "customer",
        gridProps: { md: 8 },
        required: true,
        type: "autocomplete",
        dataName: "customerId",
        label: "Customer",
        validate: (props) => {
          const customerIds = extraProps?.customers?.map((c) => c._id);

          if (
            props.data?.customerId &&
            !customerIds?.includes(props.data?.customerId)
          ) {
            return false;
          } else {
            return true;
          }
        },
        getErrorText:
          "Previous customer that this contract had has been removed. Please select a new one",
        setFormDataCustom: (props) => {
          const customer = extraProps?.customers?.find(
            (item) => item._id === props.value,
          );
          if (customer && props.data) {
            extraProps?.setVatTypeTooltipOnCustomer(customer);

            const newData: ContractExtended = {
              ...props.data,
              customerId: props.value,
              termsOfPaymentId:
                customer?.termsOfPaymentId || props.data?.termsOfPaymentId,
              reversedConstructionVAT:
                customer?.reversedConstructionVAT ||
                props.data?.reversedConstructionVAT ||
                false,
              yourReference: customer.yourReference,
              yourReferenceEmail: undefined,
              invoiceEmail: customer?.emailInvoice,
              VATType: getVatType(customer),
            };

            setFormData(newData);
          }
        },
        options: getSelectOptions({
          data: extraProps?.customers,
          label: (c) => getCustomerName(c),
          value: (c) => String(c._id),
        }),
      },
      {
        itemType: "customer",
        gridProps: { md: 4 },
        required: true,
        type: "autocomplete",
        dataName: "termsOfPaymentId",
        label: "Terms of payment",
        options: getSelectOptions({
          data: extraProps?.termsOfPayments,
          label: (item) => item.description,
          value: (item) => String(item._id),
        }),
        setFormData,
      },
      {
        itemType: "customer",
        gridProps: { md: 4 },
        type: "text",
        dataName: "VATType",
        label: "VAT type",
        vatTypeTooltip: extraProps?.vatTypeTooltip,
        getValue: ({ data }) => t(data?.VATType),
        disabled: true,
      },
      {
        itemType: "customer",
        gridProps: { md: 4 },
        type: "text_input",
        dataName: "orderNumber",
        label: "Your order number",
        setFormData,
      },
      {
        itemType: "customer",
        required: false,
        type: "autocomplete",
        dataName: "currencyCode",
        label: "Currency",
        gridProps: { xs: 4 },
        options: getSelectOptions({
          data: extraProps?.currencies,
          label: (c) => `${c.code} (${c.description})`,
          value: (c) => c.code,
        }),
        setFormData,
      },
      {
        itemType: "customer",
        type: "autocomplete_add",
        onClick: () => {
          extraProps?.setIsContactModalOpen(!extraProps?.isContactModalOpen);
        },
        dataName: "contactPersonId",
        label: "Customer contact person",
        options: ({ data }) =>
          getSelectOptions({
            data: extraProps?.contacts?.filter(
              (contact) => contact.customerId === data?.customerId,
            ),
            label: (c) => `${c.firstName} ${c.lastName}`,
            value: (c) => String(c._id),
          }),
        setFormDataCustom: (props) => {
          const contactPerson = extraProps?.contacts?.find(
            (c) => String(c._id) === props.value,
          );
          if (contactPerson && props.data) {
            const newData: ContractExtended = {
              ...props.data,
              contactPersonId: props.value,
              yourReference: `${contactPerson?.firstName} ${contactPerson?.lastName}`,
              yourReferenceEmail: contactPerson?.email,
            };

            setFormData(newData);
          }
        },
        // Able to select only if a customer is selected
        disabled: ({ data }) => !data?.customerId,
      },
      {
        itemType: "customer",
        required: true,
        type: "autocomplete",
        dataName: "ourReferenceId",
        label: "Our reference",
        options: getSelectOptions({
          data: extraProps?.users,
          label: (user) => `${user.firstName} ${user.lastName}`,
          value: (user) => String(user._id),
        }),
        setFormDataCustom: (props) => {
          const user = extraProps?.users?.find(
            (item) => item._id === props.value,
          );
          if (props.data) {
            const newData = {
              ...props.data,
              ourReferenceId: user?._id,
            };

            setFormData(newData);
          }
        },
      },
      {
        itemType: "customer",
        required: false,
        type: "text_input",
        dataName: "yourReference",
        label: "Your reference",
        setFormData,
      },

      {
        itemType: "customer",
        type: "text_input",
        dataName: "yourReferenceEmail",
        label: "Contact person e-mail",
        setFormData,
      },
      {
        itemType: "customer",
        type: "text_input",
        dataName: "invoiceEmail",
        label: "Invoice e-mail",
        setFormData,
      },
    ],
  };

  return structure;
};

export const toData = ({ data }: { data?: Contract | undefined }) => {
  if (data === undefined) {
    return undefined;
  }
  if (data.renewalTerms?.renewalType === "manual") {
    try {
      delete data?.cancellationTerms;
    } catch (error) {}
  }

  const tmpDimensionItems: TmpDimensionItems = listToDic<DimensionItemContract>(
    data?.dimensionItems || [],
    (v) => String(v.dimensionId),
  );

  return {
    ...data,
    tmpDimensionItems,
  } as ContractExtended;
};

export const toSubmitData = ({
  data,
  initData,
  customFields,
}: {
  data: Partial<ContractExtended> | undefined;
  initData: Contract | undefined;
  customFields: CustomField[] | undefined;
}) => {
  if (data === undefined) {
    return data;
  }
  const submitData = clone(data);
  submitData.dimensionItems = dicToList(submitData.tmpDimensionItems);

  //Fix;
  if (submitData.dimensionItems) {
    for (let item of submitData.dimensionItems) {
      //@ts-ignore
      delete item._id;
    }
  }

  // Make sure no trailing spaces for emails
  submitData.yourReferenceEmail = submitData.yourReferenceEmail?.trim();
  submitData.invoiceEmail = submitData.invoiceEmail?.trim();

  delete submitData.tmpDimensionItems;

  const partialData = toUpdateData<Partial<ContractExtended>>({
    data: submitData,
    initData,
  });

  let isCustomFieldUpdate = false;
  const dicCustomFieldData = listToDic(
    clone(data?.customFieldsData) || [],
    (e) => e.customFieldId,
  );

  // Restructure customFieldsData
  customFields?.forEach((customField) => {
    if (Object.keys(partialData).includes(customField.name)) {
      isCustomFieldUpdate = true;
      if (dicCustomFieldData[String(customField?._id)]) {
        dicCustomFieldData[String(customField?._id)].value =
          partialData[customField.name];
      } else if (customField._id) {
        dicCustomFieldData[String(customField?._id)] = {
          customFieldId: customField._id,
          value: partialData[customField.name],
        };
      }

      // @ts-ignore
      if (dicCustomFieldData[String(customField?._id)]?._id) {
        // @ts-ignore
        delete dicCustomFieldData[String(customField?._id)]?._id;
      }

      delete partialData[customField.name];
    }
  });

  // cleanup data
  const cleanedPartialData = removeKeysWithEmptyStringAsValue(partialData);
  if (isCustomFieldUpdate) {
    cleanedPartialData.customFieldsData = dicToList(dicCustomFieldData);
  }

  return cleanedPartialData;
};
