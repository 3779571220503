import { escape } from "src/utils/translate";
import { ReportGetRequestQuery } from "src/accurasee-backend-types/app/report/report.types";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useGetReportTemplatesQuery } from "src/redux/services/ReportTemplateService";
import { useParams } from "react-router-dom";
import InsightReport from "src/screens/App/Insights/InsightReport";
import Page from "src/components/Basic/Mixed/Pages/Page";

const ContractReports = () => {
  const { contractId } = useParams<{ contractId: string }>();
  const { data: contract, isLoading } = useGetContractQuery(contractId);

  const { data: dataQuery, isLoading: isReportTemplatesLoading } =
    useGetReportTemplatesQuery(undefined);
  const reportTemplates = dataQuery?.data || [];

  const baseFilter: Partial<ReportGetRequestQuery> = {
    // Default is this fiscal year, for ex. from 1.1.2023 - today's date
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(),
    resolution: "month",
  };

  return (
    <Page
      label="Reports"
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        { label: "Reports" },
      ]}
      isLoading={isLoading || isReportTemplatesLoading}
    >
      {reportTemplates.length > 0 ? (
        reportTemplates?.map((reportTemplate) => (
          <InsightReport
            key={reportTemplate.name}
            reportTemplateId={String(reportTemplate._id)}
            reportTemplateName={reportTemplate.name}
            baseFilter={baseFilter}
          />
        ))
      ) : (
        <InsightReport
          reportTemplateName="Main report"
          baseFilter={baseFilter}
        />
      )}
    </Page>
  );
};

export default ContractReports;
