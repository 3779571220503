import { tagTypes } from "../api";

// To ensure correct typeing when using simple list of tags strings
export const getPlainListAndIdTags = (
  ...listTypes: (typeof tagTypes)[number][]
) => {
  return (result: any, error: any, id: any) => [
    ...listTypes,
    ...listTypes.map((t) => ({ type: t, id: "LIST" })),
    ...listTypes.map((t) => ({ type: t, id })),
  ];
};

export const listTag = (type: (typeof tagTypes)[number]) => () => [
  { type, id: "LIST" },
];
export const listTagTwo =
  (type1: (typeof tagTypes)[number], type2: (typeof tagTypes)[number]) =>
  () => [
    { type: type1, id: "LIST" },
    { type: type2, id: "LIST" },
    type1,
    type2,
  ];
export const listTagMultiple =
  (...types: (typeof tagTypes)[number][]) =>
  () =>
    types.map((t) => ({ type: t, id: "LIST" }));

export const docGetTag =
  (type: (typeof tagTypes)[number]) => (result: any, error: any, id: any) => [
    { type, id },
  ];
export const docGetTagMultipleList =
  (
    type: (typeof tagTypes)[number],
    ...listTypes: (typeof tagTypes)[number][]
  ) =>
  (result: any, error: any, id: any) => {
    return [{ type, id }, ...listTypes.map((t) => ({ type: t, id: "LIST" }))];
  };
export const docPatchTag =
  (type: (typeof tagTypes)[number]) =>
  (result: any, error: any, { id }: any) => [
    { type, id },
    { type, id: "LIST" },
    type,
  ];

export const docPatchTagNew =
  (type: (typeof tagTypes)[number]) =>
  (result: any, error: any, { id }: any) => [
    { type, id },
    { type, id: "LIST" },
    type,
  ];

export const docPatchTagPlusExtraListTag =
  (type: (typeof tagTypes)[number], extraType: (typeof tagTypes)[number]) =>
  (result: any, error: any, { id }: any) => [
    { type, id },
    { type, id: "LIST" },
    { type: extraType, id: "LIST" },
  ];
export const docDeleteTags =
  (type: (typeof tagTypes)[number]) => (result: any, error: any, id: any) => [
    { type, id },
    { type, id: "LIST" },
    { type },
    type,
  ];
export const docListTags =
  (type: (typeof tagTypes)[number]) => (result: any, error: any, id: any) =>
    result
      ? [
          ...result?.data?.map((doc: any) => ({ type, id: doc._id })),
          { type, id: "LIST" },
        ]
      : [];
export const docMultipleListTag =
  (...listTypes: (typeof tagTypes)[number][]) =>
  (result: any, error: any, id: any) => {
    return listTypes;
  };
