import { UserFormTableGetStructure } from "src/hooks/useFormTable";

import clone from "src/utils/clone";
import {
  CommonCallbackProps,
  StructureItemTable,
} from "../../../../components/Builders/Table/CommonBuilderTableTypes";
import { SxProps } from "@mui/material";
import {
  namespaces,
  Translation,
} from "../../../../accurasee-backend-types/app/translation/translation.types";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";
import {
  Language,
  LanguageCode,
  languageCodes,
} from "src/accurasee-backend-types/app/language/language.types";
import {
  getNestedObjectData,
  setNestedObjectData,
} from "../../../../utils/nestedData";

export type TranslationExtraStatus = "" | "Added" | "Deleted" | "Updated";
export type TranslationExtraStatusMissed = "" | "Missed";
export type TranslationExtraCellStatus = "" | "Updated" | "Missed";
export type TranslationExtra = Omit<Translation, "languages"> & {
  languages: {
    [key in LanguageCode]?: {
      originalValue: string;
      value: string;
      status: TranslationExtraCellStatus;
    };
  };
  status: TranslationExtraStatus;
  statusMissed: TranslationExtraStatusMissed;
  stackTrace?: string;
};

export type TranslationGetStructureExtraProps = {
  languages: Language[];
};

const isCellStatus = (
  d: TranslationExtra["languages"],
  status: TranslationExtraCellStatus,
) => {
  return Object.keys(d).reduce((isUpdate, key) => {
    return isUpdate || (d[key] && d[key]?.status === status);
  }, false);
};

const getStructure: UserFormTableGetStructure<
  TranslationExtra,
  TranslationGetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  const setFormDataCustom = (props: any) => {
    const { item, data, rowIndex, value } = props;
    let newData = clone(data);

    // update item status
    if (newData[rowIndex].status !== "Added") {
      const dataNameOriginal =
        item.dataName.split(".").slice(0, -1).join(".") + ".originalValue";
      const dataNameStatus =
        item.dataName.split(".").slice(0, -1).join(".") + ".status";

      if (
        getNestedObjectData({
          data: data[rowIndex],
          key: dataNameOriginal,
        }) !== value
      ) {
        newData[rowIndex].status = "Updated";
        setNestedObjectData({
          data: newData[rowIndex],
          key: dataNameStatus,
          value: "Updated",
        });
        // setNestedObjectData({
        //   data: newData[rowIndex],
        //   key: dataNameOriginal,
        //   value: "",
        // });
      } else {
        // check if any other field is updated
        setNestedObjectData({
          data: newData[rowIndex],
          key: dataNameStatus,
          value: "",
        });
        newData[rowIndex].status = isCellStatus(
          newData[rowIndex].languages,
          "Updated",
        )
          ? "Updated"
          : "";
      }
    }

    setNestedObjectData({ data: newData[rowIndex], key: item.dataName, value });

    setFormData(newData);
  };

  const sx = (language: LanguageCode) => {
    const _sx: (props: CommonCallbackProps<TranslationExtra>) => SxProps = (
      props,
    ) => {
      let styling: Partial<{}> = { width: "100%" };

      const statusRow =
        props.data[props.rowIndex].status !== "Updated"
          ? props.data[props.rowIndex].status
          : "";
      const statusCell =
        props.data[props.rowIndex].languages[language as LanguageCode]?.status;

      switch (statusRow || statusCell) {
        case "Deleted":
          styling = {
            ...styling,
            "& > div": {
              backgroundColor: "var(--negative-3)",
            },
          };
          break;
        case "Added":
          styling = {
            ...styling,
            "& > div": {
              backgroundColor: "var(--yellow-1)",
            },
          };
          break;
        case "Updated":
          styling = {
            ...styling,
            "& > div": {
              backgroundColor: "var(--green-4)",
            },
          };
          break;
        case "Missed":
          styling = {
            ...styling,
            "& > div": {
              backgroundColor: "var(--information)",
            },
          };
          break;
      }

      return styling;
    };

    return _sx;
  };

  const getHighlighted = (props: CommonCallbackProps<TranslationExtra>) => {
    const value = props.data[props.rowIndex];
    return value.status !== "";
  };

  const textInputColor: (
    props: CommonCallbackProps<TranslationExtra>,
  ) => BaseTextFieldProps["color"] = (props) => {
    return getHighlighted(props) ? "primary" : "secondary";
  };

  const textInputFocused: (
    props: CommonCallbackProps<TranslationExtra>,
  ) => BaseTextFieldProps["focused"] = (props) => {
    return getHighlighted(props);
  };

  // Create columns based on languages
  const columns =
    extraProps?.languages.map(
      (l) =>
        ({
          type: "text_input",
          dataName: `languages.${l.code}.value`,
          disabled: (props) => props.data[props.rowIndex].status === "Deleted",
          headerLabel: l.name,
          sx: sx(l.code),
          textInputFocused,
          textInputColor,
          setFormDataCustom,
        }) as StructureItemTable<TranslationExtra>,
    ) || [];

  return {
    items: [
      {
        type: "text",
        dataName: "key",
        disabled: (props) => props.data[props.rowIndex].status === "Deleted",
        headerLabel: "Key",
        textInputFocused,
        textInputColor,
        setFormDataCustom,
      },
      {
        type: "text",
        dataName: "namespace",
        disabled: (props) => props.data[props.rowIndex].status === "Deleted",
        headerLabel: "Namespace",
        textInputFocused,
        textInputColor,
        setFormDataCustom,
        filter: true,
        filterOptions: {
          names: [...namespaces],
        },
      },
      {
        headerLabel: "Action",
        type: "badge",
        dataName: "status",
        filter: true,
        filterOptions: {
          names: ["Added", "Updated", "Deleted"],
        },
      },
      {
        headerLabel: "Lacks translations",
        type: "icon",
        iconType: "check",
        dataName: "statusMissed",
        showCellWhen: ({ data, rowIndex }) =>
          !!(data[rowIndex].statusMissed === "Missed"),
        filter: true,
        filterOptions: {
          names: ["Missed"],
        },
      },
      ...columns,
      {
        type: "icon_button",
        iconType: "debug",
        dataName: "stackTrac",
        showCellWhen: ({ data, rowIndex }) => !!data[rowIndex].stackTrace,
        onClick: ({ rowIndex, data }) => {
          alert(
            `Key: ${data[rowIndex].key}\n\n` +
              "-> Stack trace for debugging where translation was triggered in the code.\n-> You can also see the stack trace printed in the console\n\n" +
              data[rowIndex].stackTrace,
          );
        },
      },
      {
        type: "icon_button",
        iconType: (props) =>
          props.data[props.rowIndex].status === "Deleted" ? "undo" : "delete",
        dataName: "delete",
        onClick: ({ rowIndex, data }) => {
          let newData = clone(data);

          // update item status
          newData[rowIndex].status =
            data[rowIndex].status === "Deleted"
              ? !newData[rowIndex]._id
                ? "Added"
                : isCellStatus(newData[rowIndex].languages, "Updated")
                  ? "Updated"
                  : ""
              : "Deleted";
          setFormData(newData);
        },
      },
    ],
  };
};

export const checkIfPlaceHolderOrEmpty = (value: string) => {
  if (!value) return true;

  const [languageCode, numberPart] = value.split("-");
  return (
    (languageCodes as ReadonlyArray<string>).includes(languageCode) &&
    !isNaN(parseFloat(numberPart))
  );
};

export const toData = ({
  data,
  status,
}: {
  data: Translation[] | undefined;
  status?: string;
}) => {
  const keys: string[] = [];

  const formData =
    data?.map((d) => {
      let isMissing = false;
      const languages = Object.keys(d.languages).reduce((dic, key) => {
        dic[key] = {
          originalValue: d.languages[key],
          value: d.languages[key],
          status: checkIfPlaceHolderOrEmpty(d.languages[key]) ? "Missed" : "",
        };

        isMissing = isMissing || dic[key].status === "Missed";
        return dic;
      }, {});

      const isDuplicate = keys.includes(d.key + d.namespace);
      keys.push(d.key + d.namespace);

      return {
        ...d,
        languages,
        status: status !== undefined ? status : isDuplicate ? "Deleted" : "",
        statusMissed: isMissing ? "Missed" : "",
      } as TranslationExtra;
    }) || ([] as TranslationExtra[]);
  return formData;
};

export const toSubmitData = ({
  data,
}: {
  data: TranslationExtra[] | undefined;
}) => {
  const submitData = data?.reduce(
    (
      dic: { create: Translation[]; delete: string[]; update: Translation[] },
      d,
    ) => {
      const { status, statusMissed, ...add } = d;

      if (!add.key) {
        return dic;
      }

      switch (status) {
        case "Added":
          const { stackTrace: _, ...rest } = add;
          dic.create.push({
            ...rest,
            languages: Object.keys(d.languages).reduce((dic, key) => {
              dic[key] = d.languages[key].value;
              return dic;
            }, {}),
          });
          break;
        case "Deleted":
          if (d._id) {
            dic.delete.push(d._id.toString());
          }
          break;
        case "Updated":
          dic.update.push({
            _id: add._id,
            key: add.key,
            namespace: add.namespace,
            languages: Object.keys(d.languages).reduce((dic, key) => {
              dic[key] = d.languages[key].value;
              return dic;
            }, {}),
          });
          break;
      }

      return dic;
    },
    { create: [], delete: [], update: [] },
  ) as { create: Translation[]; delete: string[]; update: Translation[] };

  return submitData;
};

export const toInitialSubmitData = () => {
  return { create: [], delete: [], update: [] } as {
    create: Translation[];
    delete: string[];
    update: Translation[];
  };
};

export default getStructure;
