import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonSubmitCore from "src/components/Basic/Mixed/Buttons/ButtonSubmitCore";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import useFormContainer from "src/hooks/useFormContainer";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Grid, Typography } from "@mui/material";
import {
  ContractOffer,
  SendContractOffer,
} from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";
import { getStructure } from "./SendContractOfferModalStructure";
import { useParams } from "react-router-dom";
import { useSendContractOfferMutation } from "src/redux/services/ContractOfferService";
import MicrosoftDriveTable from "src/components/Basic/Mixed/Tables/MicrosoftDriveTable";
import { useContext, useState } from "react";
import { MicrosoftDriveData } from "../../../../hooks/useMicrosoftDrive/useMicrosoftDriveList";
import useOnFormSubmitCustom from "../../../../hooks/useOnFormSubmitCustom";
import { useSnackbar } from "notistack";
import { MicrosoftDriveContext } from "../../../../context/MicrosoftDriveProvider/MicrosoftDriveProvider";
import getContractOfferExternalId from "../../../../utils/getContractOfferExternalId";
import { EMAIL_LINEBREAK } from "../../../../accurasee-backend-types/shared/constants/general";

interface SendContractOfferProps {
  contractOffer?: Partial<ContractOffer>;
  emailRecipient?: string;
  openModal: boolean;
  onClose: () => void;
}

const SendContractOfferModalWithForm = ({
  contractOffer,
  emailRecipient,
  openModal,
  onClose,
}: SendContractOfferProps) => {
  const [t] = useTranslation();
  const { contractOfferId } = useParams<{ contractOfferId: string }>();
  const [selectedRows, setSelectedRows] = useState<MicrosoftDriveData[]>([]);
  const [sendContractOffer] = useSendContractOfferMutation();
  const { enqueueSnackbar } = useSnackbar();
  const initialFormData = { message: "" };
  const { microsoftDriveClient } = useContext(MicrosoftDriveContext);

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<SendContractOffer>({
    initialFormData,
    getStructure,
  });

  // Use <br /> to display break lines
  const submitData = {
    message: formData?.message.replace(/\n/g, EMAIL_LINEBREAK),
  };

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmitCustom({
    onSubmit: async () => {
      if (contractOfferId) {
        const formData = new FormData();

        formData.append("message", submitData?.message || "");

        for (const row of selectedRows) {
          const fileBlob = await microsoftDriveClient?.downloadFileWithFetch(
            row.origin,
          );
          if (fileBlob) {
            // Append the file to the FormData
            formData.append("files", new File([fileBlob], row.origin.name));
          }
        }

        const res: any = await sendContractOffer({
          id: contractOfferId,
          data: formData,
        });

        if (res?.error) {
          console.error(res.error);
          enqueueSnackbar(`${t("Could not send email")}`, { variant: "error" });
        } else {
          enqueueSnackbar(t("Email sent"), { variant: "success" });
        }

        return { action: "update", status: res.error ? "failure" : "success" };
      } else {
        return { action: "update", status: "failure" };
      }
    },
    onSuccess: () => {
      setFormData(initialFormData);
      onClose();
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await onFormSubmit();
      }}
      ref={refForm}
    >
      <ModalWrapper
        handleCloseModal={() => onClose()}
        openModal={openModal}
        label={"Send offer"}
      >
        <SectionContainer>
          <Grid item xs={12}>
            <Typography sx={{ textAlign: "center" }}>
              {t(
                "Please provide a message that will be included in the email that is sent to this contact person",
              )}
            </Typography>
            <Typography sx={{ fontWeight: 600, textAlign: "center" }}>
              {emailRecipient}
            </Typography>
          </Grid>
          {BuildItems({
            items: structure.items,
            ...commonPropsBuildItem,
          })}
          <MicrosoftDriveTable
            collectionName={"contract_offers"}
            label={"Select documents you want to include in email"}
            leafFolder={getContractOfferExternalId(contractOffer, true)}
            onlyTable={true}
            prefixDocumentName={getContractOfferExternalId(contractOffer)}
            setSelectedRows={setSelectedRows}
          />
        </SectionContainer>

        <SectionWithButtonContainer buttonCenter>
          <ButtonGroup>
            <ButtonCancel onClick={() => onClose()} />
            <ButtonSubmitCore
              initialSubmitData={{}}
              isSubmitting={isSubmitting}
              isValid={isFormValid}
              label="send"
              onSubmit={async () => {
                refForm.current.requestSubmit();
                setHasTriedToSubmit(true);
              }}
              submitData={submitData}
            />
          </ButtonGroup>
        </SectionWithButtonContainer>
      </ModalWrapper>
    </form>
  );
};

export default SendContractOfferModalWithForm;
