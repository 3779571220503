import ApprovePlannedInvoicesModalWithForm from "../../Modals/ApprovePlannedInvoicesModalWithForm";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonCoinvoice from "src/components/Basic/Mixed/Buttons/ButtonCoinvoice";
import FilterOwnContractsButtonTooltip from "src/components/Basic/Mixed/Buttons/FilterOwnContractsButtonTooltip";
import getSummaries from "src/utils/getSummaries";
import InvoiceRowsSubtable from "../InvoiceRowsSubtable";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import PromptDialog from "src/components/Basic/Simple/Dialogs/PromptDialog";
import SectionForTableWithToolbar from "src/components/Basic/Simple/Sections/SectionForTableWithToolbar";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";
import useOnSubmit from "src/hooks/useOnSubmit";
import usePlannedInvoicesTableStructure from "./usePlannedInvoicesTableStructure";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Box } from "@mui/material";
import { Filter } from "src/hooks/useMUIDataTableFilterStates";
import { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import { PlannedInvoiceGetList } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  useDeletePlannedInvoiceMutation,
  useGetPlannedInvoicesQuery,
} from "src/redux/services/PlannedinvoiceService";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useGetCustomersQuery } from "src/redux/services/CustomerService";

interface InvoiceTableProps {
  label?: string;
  plannedInvoiceFilter: Filter;
  useColumns: string[];
  redirectFoundationPath?: string;
}

const PlannedInvoicesTable = ({
  label,
  plannedInvoiceFilter,
  useColumns,
  redirectFoundationPath,
}: InvoiceTableProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();

  const { data: companyQuery, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);
  const company = companyQuery?.company;

  const { data: customersRes, isLoading: isLoadingCustomers } =
    useGetCustomersQuery(undefined);
  const customers = customersRes?.data || [];

  const [selectedRows, setSelectedRows] = useState<PlannedInvoiceGetList[]>([]);
  const [selectedRowsIndex, setSelectedRowsIndex] = useState<number[]>([]);
  const [errorCoinvoice, setErrorCoinvoice] = useState("");
  const { contractId, getStructure, openModal, setOpenModal, plannedInvoice } =
    usePlannedInvoicesTableStructure();

  const [deletePlannedInvoice] = useDeletePlannedInvoiceMutation();

  const onSubmit = useOnSubmit({
    apiMutations: { delete: deletePlannedInvoice },
    name: "planned invoice",
  });

  const [plannedInvoices, setPlannedInvoices] = useState<
    PlannedInvoiceGetList[]
  >([]);

  const {
    options,
    data,
    isLoading: isPlannedInvoicesLoading,
  } = useMUIDataTableOptionsServerSide<PlannedInvoiceGetList>({
    dataName: "planned invoices",
    filterInit: {
      base: {
        ...plannedInvoiceFilter,
      },
      match: {
        contractId,
      },
    },
    filterOptions: {
      isRowSelectable: ((dataIndex: number) => {
        return plannedInvoices.length !== dataIndex;
      }) as MUIDataTableOptions["isRowSelectable"],
      selectableRows: "multiple",
      selectFunction: (_, current: any) => {
        const newListIndex = current.map((c: any) => c.dataIndex);
        const newList = current.map((c: any) => plannedInvoices[c.dataIndex]);
        setSelectedRowsIndex(newListIndex);
        setSelectedRows(newList);
      },
      rowsSelected: selectedRowsIndex,
      searchPlaceholder: `${t("Search for")} ${t("Number").toLowerCase()}, ${t(
        "Name",
      ).toLowerCase()}, ${t("Customer name").toLowerCase()} ${t("or")} ${t(
        "Status",
      ).toLowerCase()}...`,
      deleteFunction: (rowsDeleted, newTableData): void | false => {
        const plannedInvoicesToDelete = rowsDeleted.data.map(
          (row) => plannedInvoices[row.dataIndex],
        );

        for (const plannedInvoice of plannedInvoicesToDelete) {
          if (!plannedInvoice?.permissions?.deleteRights) {
            enqueueSnackbar(
              `${t("Could not delete - deletion rights are missing")}`,
              {
                variant: "error",
              },
            );
            return false;
          } else if (plannedInvoice?._id) {
            onSubmit({ data: { delete: plannedInvoice?._id } });
          }
        }
      },
    },
    isRowExpandable: ((dataIndex: number) => {
      return plannedInvoices.length !== dataIndex;
    }) as MUIDataTableOptions["isRowExpandable"],
    subTableFunction: (rowData: any, rowMeta: any) => {
      const plannedInvoice = plannedInvoices[rowMeta.dataIndex];
      return (
        <SubTableContainer>
          <InvoiceRowsSubtable
            currencyCode={plannedInvoice.currencyCode}
            invoiceRows={plannedInvoice.invoiceRows}
            useColumns={[
              "itemDescription",
              "quantity",
              "pricePerUnit",
              "discount",
              "totalExcludingVAT",
              "percentVAT",
              "total",
              "isIndex",
            ]}
          />
        </SubTableContainer>
      );
    },
    triggerChangeOnFilterInit: contractId ? ["contractId"] : [],
    useGetDataQuery: useGetPlannedInvoicesQuery,
    customButton: ({ additionalFilter, setAdditionalFilter }) => {
      return (
        <Box sx={{ display: "inline-flex", columnGap: "var(--space-8px)" }}>
          <FilterOwnContractsButtonTooltip
            additionalFilter={additionalFilter}
            setAdditionalFilter={setAdditionalFilter}
          />
          <ButtonCoinvoice
            onPostMerge={() => {
              setSelectedRowsIndex([]);
              setSelectedRows([]);
            }}
            selectedRows={selectedRows}
            setErrorCoinvoice={setErrorCoinvoice}
          />
        </Box>
      );
    },
  });

  useEffect(() => {
    if (!isPlannedInvoicesLoading) {
      setPlannedInvoices(data);
    }
  }, [data]);

  const { idSummaries, dataWithSummaries: plannedInvoicesWithSummaries } =
    getSummaries({
      data: plannedInvoices,
      sumTextKey: "number",
      keys: [
        { name: "totalExcludingVAT" },
        { name: "totalVAT" },
        { name: "total" },
      ],
    });

  let plannedInvoiceColumns: MUIDataTableColumnDef[] = getStructure({
    extraProps: {
      ...(redirectFoundationPath ? { redirectFoundationPath } : {}),
      currencyCode: company?.currencyCode,
      customers,
      idSummaries,
    },
  })
    .items.map((item) =>
      BuilderColumnOptionsSingle({
        data: plannedInvoicesWithSummaries,
        disabled: true,
        item,
      }),
    )
    .filter((column: any) => useColumns.includes(column.name));

  return (
    <SectionForTableWithToolbar
      transparent={!label}
      label={label}
      isLoading={isLoadingCustomers || isLoadingCompany}
      boxStyle={{ ...(!label ? { padding: 0 } : {}) }}
    >
      <MuiDataTableWrapper
        columns={plannedInvoiceColumns}
        data={plannedInvoicesWithSummaries}
        isFetching={isPlannedInvoicesLoading}
        options={options}
        tableType="main-with-filter"
        noBackground={!label}
      />
      <ApprovePlannedInvoicesModalWithForm
        plannedInvoice={plannedInvoice}
        openModal={openModal}
        handleCloseModal={() => setOpenModal(false)}
      />
      <PromptDialog
        open={errorCoinvoice !== ""}
        onClose={() => setErrorCoinvoice("")}
        title={errorCoinvoice}
        message={errorCoinvoice}
      />
    </SectionForTableWithToolbar>
  );
};

export default PlannedInvoicesTable;
