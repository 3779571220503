/**
 *Created by Mikael Lindahl on 2024-06-04
 */

import { CollectionName } from "../../accurasee-backend-types/app/general/collection.types";
import { getProjectPath } from "../../context/MicrosoftDriveProvider/Utils/PathHelper";
import {
  MicrosoftDrivePickerFile,
  MicrosoftDriveService,
  MicrosoftTemplatePopulateData,
} from "../../context/MicrosoftDriveProvider/Lib/MicrosoftDriveService";
import { useSnackbar } from "notistack";

type AddDocumentFromTemplateProps<
  DocumentType extends {},
  ExtraType extends {},
> = {
  data?: MicrosoftTemplatePopulateData;
  selectedFileName: string;
  selectedGroup: string;
  selectedTemplate: MicrosoftDrivePickerFile | null;
  selectedType: string;
};

const useMicrosoftDriveCreateDocumentFromTemplate = ({
  collectionName,
  leafFolder,
  service,
}: {
  collectionName: CollectionName;
  leafFolder?: string;
  service: MicrosoftDriveService | null;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const createDocumentFromTemplate = async <
    DocumentType extends {},
    ExtraType extends {},
  >({
    data,
    selectedFileName,
    selectedGroup,
    selectedTemplate,
    selectedType,
  }: AddDocumentFromTemplateProps<DocumentType, ExtraType>) => {
    if (!service || !leafFolder || !selectedTemplate) return;

    try {
      const projectPath = getProjectPath({
        collectionName,
        config: service?.config,
        leafFolder,
      });

      // generatedItem is missing webDavUrl and webDavUrl. Need to call getFileMetadata
      // to get the missing properties and latest version
      const generatedItem = await service.addDocumentToTemplate({
        template: selectedTemplate,
        // {},
        fileName: selectedFileName,
        projectPath,
        data,
      });

      const metadata = {
        description: encodeURIComponent(
          JSON.stringify({
            group: selectedGroup,
            type: selectedType,
          }),
        ),
      };

      await service.addMetadata({ id: generatedItem.id, metadata });
      await service.addMetadata({ id: selectedTemplate.id, metadata });
      const finalItem = await service.getFileMetadata({
        driveId: generatedItem?.parentReference?.driveId,
        id: generatedItem.id,
      });

      await service.openURL(
        finalItem.webDavUrl,
        finalItem.webUrl,
        finalItem.name,
        finalItem,
      );
    } catch (e) {
      console.error("Error", e);
      enqueueSnackbar(
        "An error occurred while creating the document from the template.",
        { variant: "error" },
      );
      // setIsError(true);
    }
  };

  return { createDocumentFromTemplate };
};

export default useMicrosoftDriveCreateDocumentFromTemplate;
