import { Typography } from "@mui/material";
import {
  useCreateReportTemplateMutation,
  useGetReportTemplatesQuery,
} from "src/redux/services/ReportTemplateService";
import Page from "src/components/Basic/Mixed/Pages/Page";
import Report from "./Report";
import { Fragment } from "react";
import ButtonAddBoxIcon from "src/components/Basic/Simple/Buttons/ButtonAddBoxIcon";

const Reports = () => {
  const {
    data: dataQuery,
    isLoading: isLoadingReports,
    isFetching: isFetchingReports,
  } = useGetReportTemplatesQuery(undefined);
  const [createReport, { isLoading: isCreatingReport }] =
    useCreateReportTemplateMutation(undefined);

  const reportTemplates = dataQuery?.data || [];

  const isLoading = isLoadingReports || isCreatingReport || isFetchingReports;

  const handleCreateReport = () => {
    createReport({ name: "New report" });
  };

  return (
    <Page
      label={"Reports"}
      breadcrumbs={[{ label: "Setup" }, { label: "Reports" }]}
      isFullWidth={false}
      isLoading={isLoadingReports || isFetchingReports}
      boxRight={
        <ButtonAddBoxIcon
          label="Create report"
          onSubmit={handleCreateReport}
          isLoading={isCreatingReport}
          disabled={isLoading}
        />
      }
    >
      {reportTemplates?.length === 0 ? (
        <Typography>{"No report template exists ..."}</Typography>
      ) : (
        reportTemplates?.map((template) => (
          <Fragment key={template.name}>
            <Report data={template} />
          </Fragment>
        ))
      )}
    </Page>
  );
};

export default Reports;
