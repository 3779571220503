import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonAddBoxIcon from "src/components/Basic/Simple/Buttons/ButtonAddBoxIcon";
import DeletionModal from "src/components/Complex/Modals/DeletionModal";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Page from "src/components/Basic/Mixed/Pages/Page";
import usePathPermission from "src/hooks/usePathPermission";
import { MUIDataTableColumnDef } from "mui-datatables";
import { escape } from "../../../../../utils/translate";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import {
  getStructure,
  InvoicePlanWithPermissionsExtended,
} from "./InvoicePlansStructure";
import {
  useDeleteInvoiceplanMutation,
  useGetInvoiceplansQuery,
} from "src/redux/services/InvoiceplanService";

function InvoicePlans() {
  const navigate = useNavigate();
  const pathPermissions = usePathPermission(
    "/app/contracts/:contractId/invoiceplans/create",
  );

  let { contractId } = useParams<{ contractId?: string }>();
  const { data: contract, isLoading: isLoadingContract } =
    useGetContractQuery(contractId);

  const { data: invoicePlansBackend, isLoading: isLoadingInvoicePlans } =
    useGetInvoiceplansQuery({ contractId: contractId }, { skip: !contractId });

  const invoicePlans: InvoicePlanWithPermissionsExtended[] = (
    invoicePlansBackend?.data || []
  ).map((i, index) => ({ rowNumber: String(index + 1), ...i }));

  const [deleteInvoiceplan] = useDeleteInvoiceplanMutation();

  //Props for child [contractTypeId] for getting info is Customer changed (get customerID)
  //or user canceled changes of Customer in InvoicePlanContainer.tsx
  const location = useLocation();
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteRowData, setDeleteRowData] =
    useState<InvoicePlanWithPermissionsExtended>();
  const structure = getStructure((rowIndex) => {
    setOpenDeleteModal(true);
    setDeleteRowData(invoicePlans[rowIndex]);
  }, location);

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<InvoicePlanWithPermissionsExtended>({
      data: invoicePlans,
      item,
    }),
  );

  const { options } = useMUIDataTableOptions({
    pagination: invoicePlans.length >= 10,
  });

  return (
    <Page
      label={"Invoice plan list"}
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        {
          label: "Invoice plan list",
        },
      ]}
      boxRight={
        <ButtonAddBoxIcon
          id="direct-invoice-plan-create-page"
          permissions={pathPermissions}
          label="Create invoice plan"
          onSubmit={() =>
            navigate(`/app/contracts/${contractId}/invoiceplans/create`)
          }
        />
      }
      isLoading={isLoadingInvoicePlans || isLoadingContract}
    >
      <MuiDataTableWrapper
        data={invoicePlans || []}
        columns={columns}
        options={options}
        tableType="main-with-filter"
        noBackground={true}
      />
      <DeletionModal
        deleteData={deleteRowData}
        dataType={"invoice plan"}
        openModal={isOpenDeleteModal}
        deleteDataFunction={deleteInvoiceplan}
        setIsOpenDeleteModal={setOpenDeleteModal}
      />
    </Page>
  );
}

export default InvoicePlans;
