/**
 *Created by Mikael Lindahl on 2023-02-22
 */
import React from "react";
import {
  CommonBuilderTableTypes,
  CommonColumnOptionTypes,
  CommonTypes,
} from "./CommonBuilderTableTypes";
import { TableItemType } from "src/accurasee-backend-types/frontend/Table/CommonBuilderTableTypesShared";
import { MUIDataTableColumnOptions, MUIDataTableMeta } from "mui-datatables";
import ActionRowColumnItem from "./ColumnItems/ActionRowColumnItem";
import AutocompleteColumnItem from "./ColumnItems/AutocompleteColumnItem";
import BadgeColumnItem from "./ColumnItems/BadgeColumnItem";
import TextColumnItem from "./ColumnItems/TextColumnItem";
import toDate from "../../../utils/dateslash";
import DateInputColumnItem from "./ColumnItems/DateInputColumnItem";
import LinkColumnItem from "./ColumnItems/LinkColumnItem";
import IconColumnItem from "./ColumnItems/IconColumnItem";
import TextInputColumnItem from "./ColumnItems/TextInputColumnItem";
import parseNumberInput from "../../../utils/parseNumberInput";
import formatValueDisplayedCurrency from "../Utils/formatValueDisplayedCurrency";
import SelectorColumnItem from "./ColumnItems/SelectorColumnItem";
import SwitchColumnItem from "./ColumnItems/SwitchColumnItem";
import TextWithEditButtonColumnItem from "./ColumnItems/TextWithEditButtonColumnItem";
import TextWithLabelColumnItem from "./ColumnItems/TextWithLabelColumnItem";
import getShowCellWhen from "./Utils/getShowCellWhen";
import get from "./Utils/get";
import ButtonColumnItem from "./ColumnItems/ButtonColumnItem";

type GetColumnOptionsTProps = CommonTypes;
type GetColumnOptions = (props: GetColumnOptionsTProps) => {
  customBodyRender: (
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: (value: string) => void,
  ) => string | React.ReactNode;
};

const getColumnOptions: GetColumnOptions = (props) => {
  const customBodyRender: MUIDataTableColumnOptions["customBodyRender"] = (
    value,
    tableMeta,
  ) => {
    // The field is highlighted if it is different from the initial value

    let options: Partial<CommonColumnOptionTypes>;

    let alignColumnContent = props.item.alignColumnContent;

    const commonCallbackProps = {
      item: props.item,
      data: props.data,
      rowIndex: tableMeta.rowIndex,
    };

    const show = getShowCellWhen(commonCallbackProps);

    const type: TableItemType = !show
      ? "empty"
      : get("type", commonCallbackProps);

    switch (type) {
      case "action_row":
        options = { ColumnItem: ActionRowColumnItem };
        break;
      case "autocomplete":
        options = { ColumnItem: AutocompleteColumnItem };
        break;
      case "badge":
        options = { ColumnItem: BadgeColumnItem };
        break;
      case "button":
      case "button_primary":
      case "button_secondary":
        options = {
          ColumnItem: ButtonColumnItem,
          buttonType: props.item.type as
            | "button"
            | "button_primary"
            | "button_secondary",
        };
        break;
      case "date":
        options = { ColumnItem: TextColumnItem, format: toDate };
        break;
      case "date_input":
        options = { ColumnItem: DateInputColumnItem };
        break;
      case "link":
        options = { ColumnItem: LinkColumnItem };
        break;
      case "icon":
        options = { ColumnItem: IconColumnItem };
        break;
      case "icon_button":
        options = { ColumnItem: IconColumnItem, isClickable: true };
        break;
      case "empty":
        options = {};
        break;
      case "number":
        options = { ColumnItem: TextColumnItem, justifyContent: "flex-end" };
        break;
      case "number_input":
        options = {
          ColumnItem: TextInputColumnItem,
          parse: (value) => parseNumberInput(value),
        };
        break;
      case "number_input_currency":
        options = {
          ColumnItem: TextInputColumnItem,
          formatValueDisplayed: formatValueDisplayedCurrency,
          isCurrency: true,
          parse: (value) => parseNumberInput(value),
        };
        break;
      case "percent":
        options = { ColumnItem: TextColumnItem, format: (val) => `${val} %` };
        break;
      case "percent_input":
        options = {
          ColumnItem: TextInputColumnItem,
          parse: (value) =>
            isNaN(value) || value === "" ? value : Number(value),
          style: { width: "90px" },
          unit: "%",
        };
        break;
      case "selector":
        options = { ColumnItem: SelectorColumnItem };
        break;
      case "switch":
        options = { ColumnItem: SwitchColumnItem };
        break;
      case "text":
        alignColumnContent = alignColumnContent || "start";
        options = {
          ColumnItem: TextColumnItem,
          justifyContent: alignColumnContent,
        };
        break;
      case "text_currency":
        alignColumnContent = alignColumnContent || "end";
        options = {
          ColumnItem: TextColumnItem,
          isCurrency: true,
          justifyContent: alignColumnContent,
        };
        break;
      case "text_currency_integer":
        alignColumnContent = alignColumnContent || "end";
        options = {
          ColumnItem: TextColumnItem,
          parse: (value) => Math.round(value),
          isCurrency: true,
          justifyContent: alignColumnContent,
        };
        break;
      case "text_input":
        options = { ColumnItem: TextInputColumnItem };
        break;
      case "text_with_edit_button":
        options = { ColumnItem: TextWithEditButtonColumnItem };
        break;
      case "text_with_optional_badge":
        options = {
          ColumnItem: TextColumnItem,
          justifyContent: alignColumnContent || "start",
          isTextWithOptionalBadge: true,
        };
        break;
      case "text_with_prefix_bullet":
        options = {
          ColumnItem: TextColumnItem,
          justifyContent: alignColumnContent || "start",
          isTextWithPrefixBullet: true,
        };
        break;
      case "text_with_prefix_icon":
        options = {
          ColumnItem: TextColumnItem,
          justifyContent: alignColumnContent || "start",
          isTextWithPrefixIcon: true,
        };
        break;
      case "text_with_label":
        options = { ColumnItem: TextWithLabelColumnItem };
        break;
      case "url_input":
        options = { ColumnItem: TextInputColumnItem };
        break;
    }

    const itemProps: CommonBuilderTableTypes = {
      buttonType: options.buttonType,
      data: props.data,
      disabled: props.disabled,
      format: options.format,
      helperText: props.helperText,
      formatValueDisplayed: options.formatValueDisplayed,
      isClickable: options.isClickable,
      isCurrency: options.isCurrency,
      isTextWithPrefixBullet: options.isTextWithPrefixBullet,
      isTextWithPrefixIcon: options.isTextWithPrefixIcon,
      isTextWithOptionalBadge: options.isTextWithOptionalBadge,
      item: props.item,
      justifyContent: options.justifyContent,
      parse: options.parse,
      rowIndex: tableMeta.rowIndex,
      style: options.style,
      t: props.t,
      unit: options.unit,
    };

    return options?.ColumnItem
      ? React.createElement(options.ColumnItem, itemProps)
      : React.createElement("div", {});
  };

  return { customBodyRender };
};

export default getColumnOptions;
