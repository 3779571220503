/**
 *Created by Mikael Lindahl on 2023-03-08
 */
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import calculateDependable from "src/utils/calculateDependable";
import {
  INVOICE_PLAN_TYPE,
  InvoicePlan,
  InvoicePlanRowExtended,
} from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import _c from "src/constants/Constants";
import toDate from "src/utils/date";
import {
  getErrorTextQuantity,
  setFormDataCustomQuantity,
  validateQuantity,
} from "src/utils/helpersBuilderTableStructure/helperInvoiceRowQuantity";
import { setFormDataCustomPricePerUnit } from "src/utils/helpersBuilderTableStructure/helperInvoiceRowPricePerUnit";
import { setFormDataCustomArticleId } from "src/utils/helpersBuilderTableStructure/helperInvoiceRowArticleId";
import getSelectOptions from "src/utils/getSelectOptions";
import getSelectOptionsTextRow from "../../../../../../../utils/getSelectOptionsTextRow";

export type GetStructureExtraProps = {
  articles: (Article | ContractArticle)[] | undefined;
  invoicePlan: InvoicePlan | undefined;
  customer: Customer | undefined;
  setOffsetRowIndex: (index: number) => void;
  setOpenModal: (b: boolean) => void;
};

export const getStructure: UserFormTableGetStructure<
  InvoicePlanRowExtended,
  GetStructureExtraProps
> = ({ setFormData, extraProps }) => {
  const isMilestone =
    extraProps?.invoicePlan?.type === INVOICE_PLAN_TYPE.milestone;
  const isSingle =
    extraProps?.invoicePlan?.type === INVOICE_PLAN_TYPE.singleinvoice;

  return {
    items: [
      {
        dataName: "rowNumber",
        headerLabel: "#",
        type: "text",
        getValue: ({ rowIndex }) => rowIndex + 1,
      },
      {
        type: "text_input",
        sx: { minWidth: _c.TABLE_ITEMS_MIN_WIDTH.ITEM_DESCRIPTION },
        dataName: "itemDescription",
        headerLabel: isMilestone ? "Milestone" : "Description",
        disabled: (props) => props.data[props.rowIndex].status === "exported",
        setFormData,
        required: true,
      },
      {
        type: "autocomplete",
        dataName: "articleId",
        headerLabel: "Article",
        disabled: (props) => props.data[props.rowIndex].status === "exported",
        options: [
          ...(isMilestone ? [] : getSelectOptionsTextRow()),
          ...getSelectOptions<Article | ContractArticle>({
            data: extraProps?.articles,
            value: "_id",
            label: (a) => `${a.number} - ${a.name}`,
          }),
        ],
        setFormDataCustom: setFormDataCustomArticleId<InvoicePlanRowExtended>({
          articles: extraProps?.articles,
          customer: extraProps?.customer,
          isMilestone,
          setFormData,
        }),
      },
      {
        type: "date_input",
        dataName: "estimatedInvoiceDate",
        headerLabel: "Est Completion",
        label: "EST Completion",
        disabled: (props) => props.data[props.rowIndex].status === "exported",
        defaultValue: new Date(),
        setFormData,
        showColumnWhen: () => isMilestone,
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
      },
      {
        type: "number_input",
        dataName: "quantity",
        headerLabel: "Quantity",
        showColumnWhen: () => !isMilestone,
        showCellWhen: (props) =>
          !isMilestone && !Boolean(props.data[props.rowIndex].textRow),
        validate: validateQuantity({ articles: extraProps?.articles }),
        getErrorText: getErrorTextQuantity,
        setFormDataCustom: setFormDataCustomQuantity<InvoicePlanRowExtended>({
          setFormData,
        }),
      },
      {
        type: "switch",
        dataName: "isQuantityEstimate",
        cellLabel: "Estimate",
        showColumnWhen: !isMilestone && !isSingle,
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        setFormData,
      },
      {
        type: "number_input_currency",
        currencyCode: extraProps?.invoicePlan?.currencyCode,
        dataName: "pricePerUnit",
        headerLabel: "Price",
        sx: { minWidth: _c.TABLE_ITEMS_MIN_WIDTH.PRICE },
        disabled: (props) => props.data[props.rowIndex].status === "exported",
        showColumnWhen: !isMilestone,
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        setFormDataCustom:
          setFormDataCustomPricePerUnit<InvoicePlanRowExtended>({
            setFormData,
          }),
      },
      {
        type: "number_input_currency",
        sx: { minWidth: _c.TABLE_ITEMS_MIN_WIDTH.PRICE },
        dataName: "pricePerUnit", // After calulated dependable totalExcludingVAT will be set to same value. Just makes more sense for milestone with this header label
        currencyCode: extraProps?.invoicePlan?.currencyCode,
        headerLabel: "Total excl. VAT",
        showColumnWhen: isMilestone,
        disabled: (props) => props.data[props.rowIndex].status === "exported",
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        setFormDataCustom: (props) => {
          let newData = [...props.data];
          newData[props.rowIndex].pricePerUnit = props.value;
          newData[props.rowIndex].quantity = 1;
          newData[props.rowIndex] = {
            ...newData[props.rowIndex],
            ...calculateDependable({
              // Set quantity: 1 for calculation
              data: { ...newData[props.rowIndex] },
            }),
          };

          setFormData(newData);
        },
      },
      {
        type: "switch",
        dataName: "isPricePerUnitEstimate",
        cellLabel: "Estimate",
        showColumnWhen: () => !isMilestone && !isSingle,
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        setFormData,
      },
      {
        type: "percent_input",
        dataName: "discount",
        showColumnWhen: () => !isMilestone,
        getErrorText: (props) => "Not allowed",
        validate: (props) => {
          const value = props.data[props.rowIndex][props.item.dataName];
          return !isNaN(value) && 0 <= value && value <= 100;
        },
        headerLabel: "Discount",
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        setFormDataCustom: (props) => {
          let newFormData = [...props.data];

          newFormData[props.rowIndex] = {
            ...newFormData[props.rowIndex],
            discount: props.value,
          };

          newFormData[props.rowIndex] = {
            ...newFormData[props.rowIndex],
            ...calculateDependable({
              data: newFormData[props.rowIndex],
            }),
          };
          setFormData(newFormData);
        },
      },
      {
        type: "text_currency",
        currencyCode: extraProps?.invoicePlan?.currencyCode,
        dataName: "totalExcludingVAT",
        showColumnWhen: () => !isMilestone,
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        headerLabel: "Subtotal",
      },
      {
        type: "text",
        dataName: "percentVAT",
        showColumnWhen: () => !isMilestone,
        getValue: ({ data, rowIndex }) =>
          `${data[rowIndex].percentVAT * 100} %`,
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        headerLabel: "VAT %",
      },
      {
        type: "text_currency",
        currencyCode: extraProps?.invoicePlan?.currencyCode,
        dataName: "totalVAT",
        getValue: ({ data, rowIndex }) =>
          (data[rowIndex].total || 0) - (data[rowIndex].totalExcludingVAT || 0),
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        headerLabel: "VAT",
      },
      {
        type: "text_currency",
        currencyCode: extraProps?.invoicePlan?.currencyCode,
        dataName: "total",
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        headerLabel: "Total",
      },
      {
        type: "switch",
        dataName: "isPeriodic",
        showColumnWhen: () => !isMilestone && !isSingle,
        showCellWhen: (props) => !Boolean(props.data[props.rowIndex].textRow),
        setFormDataCustom: (props) => {
          let newFormData = [...props.data];

          newFormData[props.rowIndex] = {
            ...newFormData[props.rowIndex],
            isPeriodic: props.value,
            offset: props.value
              ? {
                  format: {
                    value: "MMMM",
                    label: "January, February",
                  },
                  unit: "month",
                  number: 0,
                }
              : undefined,
          };

          if (newFormData[props.rowIndex]?.offset === undefined) {
            delete newFormData[props.rowIndex].offset;
          }

          setFormData(newFormData);
        },
        cellLabel: "Periodic",
      },
      {
        type: "text_with_edit_button",
        dataName: "offset",
        validate: (props) => {
          return props.data[props.rowIndex].isPeriodic
            ? props.data[props.rowIndex].offset?.unit !== undefined &&
                props.data[props.rowIndex].offset?.number !== undefined
            : true;
        },
        getErrorText: (props) => "Required",
        getValue: ({ data, rowIndex }) =>
          data[rowIndex]?.offset?.unit === undefined ||
          data[rowIndex]?.offset?.number === undefined
            ? "Missing data"
            : `${data[rowIndex]?.offset?.unit} ${data[rowIndex]?.offset?.number}`,
        headerLabel: "Offset",
        onClick: ({ rowIndex }) => {
          extraProps?.setOffsetRowIndex(rowIndex);
          extraProps?.setOpenModal(true);
        },
        showColumnWhen: ({ data }) =>
          !isMilestone && data.some((d) => d.isPeriodic),
        showCellWhen: ({ data, rowIndex }) => {
          return (
            Boolean(data[rowIndex].isPeriodic) &&
            Boolean(data[rowIndex].articleId)
          );
        },
      },
      {
        type: "badge",
        dataName: "status",
        headerLabel: "Status",
        showColumnWhen: () => isMilestone,
        showCellWhen: ({ data, rowIndex }) => Boolean(data[rowIndex].status),
        badgeAdditionalText: (props) => {
          return props.data[props.rowIndex].status === "exported"
            ? toDate(props.data[props.rowIndex].invoiceDate)
            : "";
        },
      },
      {
        showColumnWhen: !!extraProps?.invoicePlan?.indexOption?.index,
        cellLabel: "Use index",
        type: "switch",
        dataName: "isIndex",
        disabled: (props) => props.data[props.rowIndex].status === "exported",
        setFormData,
      },
      {
        type: "icon_button",
        iconType: "delete",
        dataName: "delete",
        headerLabel: "",
        disabled: (props) => props.data[props.rowIndex].status === "exported",
        onClick: ({ rowIndex, data }) => {
          let newFormData = [...data];
          newFormData.splice(rowIndex, 1);
          setFormData(newFormData);
        },
      },
    ],
  };
};
