import { AccountingSettingKey } from "./components/AccountingSettingsStructure";
import AccountingSettings from "./components/AccountingSettings";
import Page from "src/components/Basic/Mixed/Pages/Page";
import POCSettings from "./components/POCSettings";
import Tabs from "src/components/Basic/Mixed/Tabs/Tabs";

const ACCOUNTING_SETTINGS_TABS = [
  { label: "Percentage of completion" },
  { label: "Fixed price ATA", accountingSettingKey: "fixedATAAdjustment" },
  {
    label: "Running rate ATA",
    accountingSettingKey: "runningRateATAAdjustment",
  },
  {
    label: "Fixed price service order",
    accountingSettingKey: "fixedServiceOrderAdjustment",
  },
  {
    label: "Running rate service order",
    accountingSettingKey: "runningRateServiceOrderAdjustment",
  },
  {
    label: "Fixed rental adjustment",
    accountingSettingKey: "fixedRentalAdjustment",
  },
  {
    label: "Running rate rental adjustment",
    accountingSettingKey: "runningRateRentalAdjustment",
  },
] as { label: string; accountingSettingKey?: AccountingSettingKey }[];

const Accounting = () => {
  return (
    <Page
      label={"Accounting settings"}
      breadcrumbs={[{ label: "Setup" }, { label: "Accounting settings" }]}
      isFullWidth={false}
    >
      <Tabs
        name="accounting settings"
        tabs={ACCOUNTING_SETTINGS_TABS.map((e) => e.label)}
        tabContainerSx={{ marginTop: 0 }}
        tabPanelSx={{ padding: "var(--space-24px) 0" }}
      >
        {ACCOUNTING_SETTINGS_TABS.map((tab) =>
          tab?.accountingSettingKey ? (
            <AccountingSettings
              accountingSettingKey={tab.accountingSettingKey}
              label={tab.label}
            />
          ) : (
            <POCSettings />
          ),
        )}
      </Tabs>
    </Page>
  );
};

export default Accounting;
