import { ActivityLog } from "src/accurasee-backend-types/app/activity_log/activity_log.types";
import { getStructure } from "./ActivityLogsGetStructure";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetActivityLogsQuery } from "src/redux/services/ActivityLogService";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useParams } from "react-router-dom";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionTransparentForTableWithToolbar from "src/components/Basic/Simple/Sections/SectionTransparentForTableWithToolbar";
import useFormTable from "src/hooks/useFormTable";

const ActivityLogs = () => {
  const { contractOfferId } = useParams<{ contractOfferId?: string }>();

  const { data: activityLogsQuery, isLoading } = useGetActivityLogsQuery(
    { collectionId: contractOfferId },
    { skip: !contractOfferId },
  );
  const activityLogs = activityLogsQuery?.data || [];

  const { formData, structure } = useFormTable<ActivityLog>({
    initialFormData: activityLogs,
    getStructure,
  });

  const columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<ActivityLog>({
      data: formData,
      item,
    }),
  );

  const { options } = useMUIDatTableOptions({
    pagination: formData.length >= 10,
  });

  return (
    <SectionTransparentForTableWithToolbar
      isLoading={isLoading}
      label="Activity Logs"
    >
      <MuiDataTableWrapper
        nameSpace="main-table"
        tableType="main-with-filter"
        title=""
        data={formData}
        columns={columns}
        options={options}
        noBackground
        disabled
      />
    </SectionTransparentForTableWithToolbar>
  );
};

export default ActivityLogs;
