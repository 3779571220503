import { Types } from "mongoose";
import { MicrosoftDriveConfig } from "../general/microsoft_drive/microsoft_drive.types";

export const enum HIDDEN_COMPONENT_TYPE {
  SIDEBAR = "sidebar",
  HEADER_LINK_MAIN_PAGE = "header_links_main_page",
}

export const driveConfigTypes = ["bucket", "microsoft"] as const;
export type DriveConfigType = (typeof driveConfigTypes)[number];
type DriveConfig =
  | {
      type: "microsoft";
      config: MicrosoftDriveConfig;
    }
  | { type: "bucket"; config: null };

export interface HiddenComponent {
  _id?: Types.ObjectId;
  name: string;
  status: boolean;
}
export enum Cpu {
  MICRO = "200m",
  SMALL = "500m",
  MEDIUM = "1000m",
  LARGE = "2000m",
  XLARGE = "4000m",
}

export enum Memory {
  MICRO = "128Mi",
  SMALL = "256Mi",
  MEDIUM = "512Mi",
  LARGE = "1Gi",
  XLARGE = "2Gi",
  XXLARGE = "4Gi",
  XXXLARGE = "8Gi",
  XXXXLARGE = "16Gi",
}

export enum EphemeralStorage { // Deployment is rejected if the total ephermal storage for all the services is 10GB+, so these are lowered for now
  MICRO = "200Mi",
  SMALL = "400Mi",
  MEDIUM = "500Mi",
  LARGE = "1Gi",
  XLARGE = "1536Mi",
  XXLARGE = "2Gi",
  XXXLARGE = "3Gi",
  XXXXLARGE = "5Gi",
}

export interface DebitAndCredit {
  creditAccount: number;
  debitAccount: number;
}

export interface PocSettings {
  incomes: DebitAndCredit;
  labourCost: DebitAndCredit;
  supplierCost: DebitAndCredit;
  voucherDescription: string;
  voucherSeries: string;
}

export interface AccountingAdjustment {
  constructionAccount: number;
  dimension: {
    dimensionId: Types.ObjectId;
    dimensionItemId: Types.ObjectId;
  };
  euAccount: number;
  euVatAccount: number;
  exportAccount: number;
  salesAccount: number;
}

export interface AccountingSettings {
  fixedATAAdjustment?: AccountingAdjustment;
  fixedRentalAdjustment?: AccountingAdjustment;
  fixedServiceOrderAdjustment?: AccountingAdjustment;
  pocSettings?: PocSettings;
  runningRateATAAdjustment?: AccountingAdjustment;
  runningRateRentalAdjustment?: AccountingAdjustment;
  runningRateServiceOrderAdjustment?: AccountingAdjustment;
}

export interface ResourceConfig {
  memory?: Memory;
  cpu?: Cpu;
  "ephemeral-storage": EphemeralStorage;
}

export interface IntegrationServiceConfig {
  requests?: ResourceConfig;
  limits?: ResourceConfig;
}

export interface ResourcesConfig {
  runIntegrationCronjobs?: boolean;
  integrationService?: IntegrationServiceConfig;
}
export interface HiddenComponentConfig {
  _id?: Types.ObjectId;
  hiddenComponentType: HIDDEN_COMPONENT_TYPE;
  status: boolean;
  name: string;
  hiddenComponents?: HiddenComponent[];
}

export interface CompanyConfig {
  _id?: Types.ObjectId;
  accountingSettings?: AccountingSettings;
  integration?: {
    byggkollen?: {
      apiKey: string;
      clientId: string;
    };
  };
  companyId: Types.ObjectId;
  hiddenComponentConfigs?: HiddenComponentConfig[];
  drive?: DriveConfig;
  resources?: ResourcesConfig;
}

export interface CompanyConfigGet {
  _id?: Types.ObjectId;
  accountingSettings?: AccountingSettings;
  integration: CompanyConfig["integration"];
  drive?: DriveConfig;
}
