import { ActivityLog } from "src/accurasee-backend-types/app/activity_log/activity_log.types";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { EMAIL_LINEBREAK } from "../../../accurasee-backend-types/shared/constants/general";

export const getStructure: UserFormTableGetStructure<ActivityLog> = () => {
  return {
    items: [
      {
        type: "text",
        dataName: "emailSender",
        headerLabel: "Sender",
      },
      {
        type: "text",
        dataName: "emailRecipient",
        headerLabel: "Recipient",
      },
      {
        type: "text",
        dataName: "type",
        headerLabel: "Type",
      },
      {
        type: "badge",
        dataName: "action",
        headerLabel: "Action",
        alignColumnContent: "center",
      },
      {
        type: "text",
        dataName: "comment",
        headerLabel: "Comment",
        getValue: ({ data, rowIndex }) =>
          data[rowIndex]?.comment?.split(EMAIL_LINEBREAK).join(" ") || "",
      },
      {
        type: "date",
        dataName: "createdAt",
        headerLabel: "",
      },
    ],
  };
};
