/**
 *Created by Mikael Lindahl on 2024-02-07
 */
import calculateDependable from "./calculateDependable";
import { PlannedInvoiceRowExtended } from "../components/Screens/PlannedInvoiceBase/PlannedInvoiceRows/PlannedInvoiceRowsTable";
import { InvoicePlanRowExtended } from "../accurasee-backend-types/app/invoiceplan/invoiceplan.types";

const updateRowTotals = (
  rows: (InvoicePlanRowExtended | PlannedInvoiceRowExtended)[],
) => {
  for (let i = 0; i < rows.length; i++) {
    rows[i] = {
      ...rows[i],
      ...calculateDependable({
        data: rows[i],
      }),
    };
  }
};

export default updateRowTotals;
