/**
 *Created by Mikael Lindahl on 2024-01-30
 */
import PlannedInvoicesTable from "../../Complex/Tables/Invoices/PlannedInvoicesTable";
import { useParams } from "react-router-dom";

const PlannedInvoices = () => {
  let { contractId } = useParams<{ contractId: string }>();
  const plannedInvoiceFilter = {
    ...(!contractId
      ? { status: ["exported", "invoiced", "invoicedoverdue", "fullypaid"] }
      : {
          contractId,
          status: ["upcoming", "ready_to_invoice", "requires_input", "late"],
        }),
    sortBy: "invoiceDate",
    pageSize: 15,
  };

  return (
    <PlannedInvoicesTable
      plannedInvoiceFilter={plannedInvoiceFilter}
      useColumns={[
        ...[
          "customerId",
          "dueDate",
          "export",
          "invoiceDate",
          "status",
          "total",
          "totalExcludingVAT",
          "totalVAT",
        ],
        ...(contractId
          ? ["name", "number"]
          : ["numberFromContract", "projectExternalId"]),
      ]}
      redirectFoundationPath={
        contractId ? `/app/contracts/${contractId}/invoices/planned` : ""
      }
    />
  );
};

export default PlannedInvoices;
