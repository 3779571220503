import { Types } from "mongoose";
import { CompanydataTypes } from "../general/companydata.types";
import { CustomerInvoiceRow } from "../customerinvoice/customerinvoice.types";
import { TaxDeductionType } from "../general/invoice.types";
import { InvoiceStatusType } from "../plannedinvoice/plannedinvoice.types";
import { EditRights } from "../general/editrights.types";
import {
  DimensionItemContract,
  EndDateReminder,
} from "../contracts/contract.types";
import { IndexOptions } from "../contracttype/contracttype.types";
import { Subcontract } from "../subcontracts/subcontract.types";
import {
  CustomerStatus,
  customerStatusValues,
  VATTypeType,
} from "../general/global.types";

export const FixedOrVariableValues = ["fixed", "variable"] as const;
export type FixedOrVariable = (typeof FixedOrVariableValues)[number];

export const TimeUnitValues = [
  "day",
  "week",
  "month",
  "quarter",
  "year",
] as const;
type TimeUnit = (typeof TimeUnitValues)[number];
export const ReminderScheduleValues = ["invoicedate", "weekly"] as const;

// So far, only periodic vs non-periodic actually changes anything in the UI
// Row format and defaults are determined by hiddenCols and defaultRow of invoiceplan template
export const InvoicePlanTypeValues = [
  "milestone",
  "fixedprice",
  "runningrate",
  "periodic",
  "custom",
  "singleinvoice",
] as const;
export type InvoicePlanType = (typeof InvoicePlanTypeValues)[number];

export enum INVOICE_PLAN_TYPE {
  milestone = "milestone",
  fixedprice = "fixedprice",
  runningrate = "runningrate",
  periodic = "periodic",
  singleinvoice = "singleinvoice",
  custom = "custom",
}

type ReminderSchedule = (typeof ReminderScheduleValues)[number];

/**
 * @param isQuantityEstimate if true quantity is an estimate
 * @param isPricePerUnitEstimate  if true pricePerUnit is an estimate
 * @param estimatedInvoiceDate fixed payment plans have estimated dates
 */
export interface InvoicePlanRow extends Omit<CustomerInvoiceRow, "externalId"> {
  _id?: Types.ObjectId;
  plannedInvoiceId?: Types.ObjectId;
  isQuantityEstimate: boolean;
  isPricePerUnitEstimate: boolean;
  estimatedInvoiceDate?: Date;
  invoiceDate?: Date;
  importDate?: Date;
  incomplete?: boolean; // For incomplete pricing via BK
  isPeriodic: boolean;
  offset?: Offset;
  status?: InvoiceStatusType;
}

export interface offsetFormat {
  value: repeatsValueTypes;
  label?: string;
}

export const RepeatsValues = [
  "yyyy-MM-dd",
  "dd-MM-yyyy",
  "w",
  "MMMM",
  "MMM",
  "Qo",
  "QQQ",
  "yyyy",
  "yyy",
] as const;
type repeatsValueTypes = (typeof RepeatsValues)[number];

export const PostingDateFormatValues = [
  "first",
  "last",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
] as const;

export type PostingDateFormatValueTypes =
  (typeof PostingDateFormatValues)[number];

export interface InvoicingPeriod {
  allowExternalRowAdding?: boolean;
  isConsolidatedInvoices?: boolean;
  date: Date;
  ends?: Date;
  originalEndDate?: Date;
  postingDate?: PostingDateFormatValueTypes;
  repeats?: {
    unit: TimeUnit;
    number: number;
  };
}

export interface Offset {
  unit: TimeUnit;
  number: number;
  format: offsetFormat;
}

export interface MilestoneDetails {
  date: Date;
  ends: Date;
  total: number;
}

const InvoicePlanStatusValues = [
  "upcoming",
  "ongoing",
  "finished",
  "locked",
  "archived",
  "invoiced",
  "partiallyinvoiced",
  "cancelled",
] as const;
export type InvoicePlanStatus = (typeof InvoicePlanStatusValues)[number];

const CustomerInvoicePlanStatusValues = customerStatusValues;
export type InvoicePlanCustomerStatus = CustomerStatus;

export const ChildTypeValues = ["invoice-plan", "subcontract"] as const;
export type ChildType = (typeof ChildTypeValues)[number];

/**
 * The basis for generating Planned invoice(s) on the contract. Multiple invoice plans could exist on a contract
 *
 * Paymentplan: The invoice plan contains all rows that will later be invoiced in subsets as things are finished.
 *
 * Periodic: The plan generates Planned invoices according to an invoicing period, such as monthly.
 *
 * Single invoice: Generate an invoice with all rows of the plan. The invoice plan and actual invoice are effectively the same
 */
interface ContractChildBase extends CompanydataTypes {
  _id?: Types.ObjectId;
  childType: ChildType;
  contractId: Types.ObjectId;
  contractOffer?: number;
  contractRemainder?: number;
  currencyCode: string;
  customerId: Types.ObjectId;
  customerStatus: InvoicePlanCustomerStatus;
  dimensionItems: DimensionItemInvoicePlan[];
  discount: number;
  houseWork: boolean;
  houseWorkInfo?: {
    personalNumber: string;
    propertyRef: string;
    propertyType: string;
  };
  indexOption?: IndexOptions;
  invoiceEmail: string;
  includeProjectNameOnInvoice?: boolean;
  invoiceRows: InvoicePlanRow[];
  invoicingPeriod?: InvoicingPeriod; // Only on periodic and running plans
  locked?: boolean;
  milestoneDetails?: MilestoneDetails; // Only on milestone plans
  name: string;
  orderNumber?: string;
  ourReferenceId?: Types.ObjectId;
  projectId?: Types.ObjectId;
  remarks?: string;
  reversedConstructionVAT: boolean;
  taxDeductionType: TaxDeductionType;
  termsOfPaymentId?: Types.ObjectId;
  total?: number;
  totalExcludingVAT?: number;
  totalInvoiced?: number;
  totalRoundings?: number;
  totalVAT?: number;
  type: InvoicePlanType;
  VATType: VATTypeType;
  yourReference?: string;
  yourReferenceEmail?: string;
}

export interface InvoicePlan extends CompanydataTypes, ContractChildBase {
  endDateReminder?: EndDateReminder;
  endDateReminderDay?: Date;
  followContractCancellationTerms?: boolean;
  generatedFromUpdate?: boolean;
  reminder?: ReminderSchedule;
  status: InvoicePlanStatus;
  templateId?: Types.ObjectId;
}

export type InvoiceplanExtension = {
  rowNumber?: number;
};

export type InvoicePlanRowExtended = InvoicePlanRow & InvoiceplanExtension;

export type DimensionItemInvoicePlan = DimensionItemContract;

export type InvoicePlanWithPermissions = InvoicePlan & EditRights;

export type { ContractChildBase };
export { InvoicePlanStatusValues, CustomerInvoicePlanStatusValues };

export type InvoiceParent = Subcontract | InvoicePlan;
