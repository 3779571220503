import { backendApi } from "../api";
import { ReportTemplate } from "src/accurasee-backend-types/app/report_template/report_template.types";
import {
  docGetTag,
  docListTags,
  docPatchTag,
  docDeleteTags,
  listTag,
} from "./utils";

const CACHE_TYPE = "ReportTemplates";

export const reportTemplatesApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getReportTemplates: build.query<
      { data: ReportTemplate[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/report-templates", params }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    getReportTemplate: build.query<ReportTemplate, any>({
      query: (id) => ({ url: `/report-templates/${id}` }),
      transformResponse: (res) => res.data,
      providesTags: docGetTag(CACHE_TYPE),
    }),
    createReportTemplate: build.mutation<ReportTemplate, any>({
      query: (data) => ({ url: "/report-templates", method: "POST", data }),
      invalidatesTags: listTag(CACHE_TYPE),
    }),
    // updatereportTemplates: build.mutation({
    //   query: (data) => {
    //     return {
    //       url: "/report-templates",
    //       method: "PATCH",
    //       data,
    //     };
    //   },
    //   invalidatesTags: docPatchTag(CACHE_TYPE),
    // }),
    updateReportTemplate: build.mutation<ReportTemplate, any>({
      query: ({ id, ...data }) => {
        return {
          url: `/report-templates/${id}`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docPatchTag(CACHE_TYPE),
    }),
    deleteReportTemplate: build.mutation<any, ReportTemplate["_id"] | string>({
      query: (id) => ({ url: `/report-templates/${id}`, method: "DELETE" }),
      invalidatesTags: docDeleteTags(CACHE_TYPE),
    }),
  }),
  overrideExisting: false,
});

export const {
  useDeleteReportTemplateMutation,
  useGetReportTemplatesQuery,
  useGetReportTemplateQuery,
  useCreateReportTemplateMutation,
  // useUpdatereportTemplatesMutation,
  useUpdateReportTemplateMutation,
} = reportTemplatesApi;

export default reportTemplatesApi;
