/**
 *Created by Mikael Lindahl on 2023-05-09
 */

import Button from "@mui/material/Button";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { OverridableStringUnion } from "@mui/types";
import {
  ButtonPropsColorOverrides,
  ButtonPropsVariantOverrides,
} from "@mui/material/Button/Button";
import { SxProps } from "@mui/material";
import React from "react";

type ExportButtonTProps = {
  color?: OverridableStringUnion<
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning",
    ButtonPropsColorOverrides
  >;
  children: React.ReactNode;
  variant?: OverridableStringUnion<
    "text" | "outlined" | "contained",
    ButtonPropsVariantOverrides
  >;
  data: any;
  delimiter?: string;
  fileName: string;
  fileType: "csv" | "json" | "xlsx";
  sx?: SxProps;
  onDone?: () => void;
};

const ExportButton = (props: ExportButtonTProps) => {
  const delimiter = props.delimiter || ",";

  let handleExport: () => void = () => {};
  switch (props.fileType) {
    case "csv":
      handleExport = () => {
        const header = Object.keys(props.data[0]).join(delimiter);
        const rows = props.data
          .map((row: any) => Object.values(row).join(delimiter))
          .join("\n");
        const csv = `${header}\n${rows}`;

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, props.fileName);
      };
      break;
    case "json":
      handleExport = () => {
        const json = JSON.stringify(props.data, null, 2); // Format JSON with 2-space indentation
        const blob = new Blob([json], { type: "application/json" });
        saveAs(blob, props.fileName);
      };
      break;
    case "xlsx":
      handleExport = () => {
        // Create a new worksheet
        const worksheet = XLSX.utils.json_to_sheet(props.data);

        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Write the workbook to a binary string
        const binaryString = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "binary",
        });

        // Convert the binary string to an ArrayBuffer
        const buffer = new ArrayBuffer(binaryString.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < binaryString.length; i++) {
          view[i] = binaryString.charCodeAt(i) & 0xff;
        }

        // Save the file using FileSaver.js
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        saveAs(blob, props.fileName);
      };
      break;
  }

  return (
    <Button
      color={props.color || "primary"}
      component="span"
      variant={props.variant || "outlined"}
      onClick={() => {
        handleExport();
        if (props.onDone !== undefined) {
          props.onDone();
        }
      }}
      sx={props.sx}
    >
      {props.children}
    </Button>
  );
};

export default ExportButton;
